import Constants from "../utils/Constants";

export const menuItemsData = [
  {
    "title": "Exports",
    "submenu": [


      {
        "title": "Raw Items",
        "submenu": [
          { "title": "Potato" },
          { "title": "Cotton" },
          { "title": "Spices" },
          { "title": "Leather" }
        ]
      },

      {
        "title": "Manufactured Items",
        "submenu": [

          { "title": "Shoes" },
          { "title": "Hand-Crafts" }
        ]
      },

      {
        "title": "Items On Demand"

      }
    ]
  },
  {
    "title": "Imports",
    "submenu": [
      {
        "title": "Seeds",
        "submenu": [
          { "title": "Chia Seeds" },
          { "title": "Pumpkin Seeds" }
        ]
      },
      {
        "title": "Semiconductors",
        "submenu": [
          { "title": "Diodes" },
          { "title": "Silicons" },
          { "title": "Galliams" }
        ]
      }
    ]
  },
  {
    "title": "Create Order"
  },
  {
    "title": "Payment Options",
    "submenu": [
      {
        "title": "PayPal"
      },
      {
        "title": "Credit Card"
      },
      {
        "title": "Bank Transfer"
      }
    ]
  },


  { "title": Constants.MENU_ITEMS.COMPANY }

]




// export const menuItemsData = [
//   {
//     title: 'Home'
//   },
//   {
//     title: 'Game',
//     submenu: [
//       { title: 'Lether' },
//       { title: 'Cloths' },
//       { title: 'Spices' },
//       {
//         title: 'Handcrafts',
//         submenu: [
//           { title: "Toys" },
//           { title: "Pots" },
//           { title: "Utensils" },
//           {
//             title: "Warm Cloths", submenu: [
//               { title: "Made of Wool" },
//               { title: "Made of Cotton" },
//               { title: "Made of Denim" },
//               { title: "Made of Telicot" },
//             ]
//           },
//         ]
//       }
//     ]
//   },
//   { title: 'Live Coaching' },
//   { title: 'CSS' }
// ];
