import './Profile.css'


import editIcon from '../res/edit_white_icon.png'
import { useState, useEffect, useRef, useCallback } from 'react'
import Loader from '../loader/Loader.js'

import Card from '../ui/Card.js';
import AddBankDetailsDialog from './AddBankDetailsDialog.js'
import AxiosBase, { ErrorHandler } from '../axios/AxiosBase.js'
import { getProfilePicture, logMsg, logMsgExtra } from '../utils/Utils.js'
import { useSelector, useDispatch } from 'react-redux'
import MyButton from '../widgets/MyButton'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import CustomDialog from '../utils/CustomDialog'
import Constants from '../utils/Constants.js'
import FileUpload from '../utils/FileUpload'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import CardDetailsDialog from './CardDetailsDialog.js'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const Profile = () => {
    // const num=useSelector(state=>state.TimerReducer)
    const getUser = useSelector(state => state.userReducer)

    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState("")
    const [isLoading, setLoading] = useState(false)
    // const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_TEST);
    const [profileData, setProfileData] = useState(getUser)
    const [bankData, setBankData] = useState({})
    const navigate = useNavigate()
    const addBankDetailsDialogRef = useRef()
    const VERIFICATION_ICONs = { MOBILE: "mobile", EMAIL: "email" }

    const [openDialogCommand, setOpenDialogCommand] = useState(Constants.DIALOG_DEFAULT_STATE)

    const addOpenDialogCommand = useCallback(({ msg = "", task = Constants.DIALOG_TASKS.NONE, isOpen = false, isShowCancelButton = false }) => {
        setOpenDialogCommand({ message: msg, task: task, isOpen: isOpen, isShowCancelButton: isShowCancelButton })
    }, [openDialogCommand])



    const [openDataDialogCommand, setOpenDataDialogCommand] = useState({ isOpen: false, joinModel: {} })

    const addOpenDataDialogCommand = useCallback(({ isOpen = false, joinModel = {} }) => {
        setOpenDataDialogCommand({ isOpen: isOpen, joinModel: joinModel })
    }, [openDataDialogCommand])



    useEffect(async () => {
       const s= await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE)
       setStripePromise(s)
        callProfileApi();
        callBankDetailsApiCall()
    }, [])

    async function callProfileApi() {
        logMsg("calling profile api")

        try {
            setLoading(true)
            const reqData = { userId: getUser._id }
            const options = {
                method: 'POST',
                data: reqData,
                url: "/profile"

            };

            const res = await AxiosBase(options)
            const data = res.data
            logMsgExtra('profile data', data)
            setProfileData(data)


        } catch (error) {
            const msg = ErrorHandler(error)

            addOpenDialogCommand({ msg: msg, isOpen: true })
        } finally {
            setLoading(false)
        }



    }


    const setBankDataCallback = useCallback((data) => {
        setBankData(data)
    }, [bankData])

    async function callBankDetailsApiCall() {
        logMsg("calling profile api")

        try {
            setLoading(true)
            const options = {
                method: 'GET',
                url: `/bank_details/${getUser._id}`
            };

            const res = await AxiosBase(options)
            const data = res.data
            logMsgExtra('profile data', data)
            setBankDataCallback(data)


        } catch (error) {
            const msg = ErrorHandler(error)
            addOpenDialogCommand({ msg: msg, isOpen: true })
        } finally {
            setLoading(false)
        }



    }

    function handleVerificationIconClick(verifiedIcon) {

        switch (verifiedIcon) {
            case VERIFICATION_ICONs.MOBILE:

                navigate('/verify_mobile', { state: { userId: profileData._id, mobile: profileData.mobile } })
                break;
            case VERIFICATION_ICONs.EMAIL:
                navigate('/verify_email', { state: { userId: profileData._id, email: profileData.email } })
                break;
            default:
                break;
        }
    }

    async function uploadToServer(file) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('userId', getUser._id)
        try {
            setLoading(true)
            const options = {
                method: 'POST',
                url: '/upload_image',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            };

            const res = await AxiosBase(options)
            const data = res.data
            setProfileData(data)
            // addOpenDialogCommand({ msg: data.message, isOpen: true })


        } catch (error) {
            const msg = ErrorHandler(error)
            addOpenDialogCommand({ msg: msg, isOpen: true })
        } finally {
            setLoading(false)
        }
    }

    async function createPaymentIntent() {

        try {
            setLoading(true)
            const options = {
                method: 'POST',
                data: {},
                url: `/create_payment_intents_stripe`
            };

            const res = await AxiosBase(options)
            const data = res.data
            setClientSecret(data.clientSecret)
            addOpenDataDialogCommand({ isOpen: true })

        } catch (error) {
            const msg = ErrorHandler(error)
            addOpenDialogCommand({ msg: msg, isOpen: true })
        } finally {
            setLoading(false)
        }



    }

    async function addCardDetails() {

    }

    return (
        <div>
            <CustomDialog
                dialogParams={openDialogCommand}

                onClickDialogCrossBtn={e => {
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogCancelBtn={e => {

                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogOkayBtn={async e => {
                    switch (openDialogCommand.task) {
                        case Constants.DIALOG_TASKS.GO_TO_LOGIN:
                            navigate('/login')
                            break;


                        default:

                            break;
                    }
                    addOpenDialogCommand({ isOpen: false })

                }}
                dialogStatusCallback={(isDialogOpened) => {
                    if (isDialogOpened) {

                    } else {

                    }
                }} >

            </CustomDialog>
            {
                clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CardDetailsDialog
                            dialogParams={openDataDialogCommand}

                            onClickDialogCrossBtn={e => {
                                addOpenDataDialogCommand({ isOpen: false })
                            }}
                            onClickDialogCancelBtn={e => {

                                addOpenDataDialogCommand({ isOpen: false })
                            }}
                            onClickDialogOkayBtn={e => {

                                addOpenDataDialogCommand({ isOpen: false })

                            }}
                            dialogStatusCallback={(isDialogOpened) => {
                                if (isDialogOpened) {
                                    logMsg("dialog opened")
                                } else {
                                    logMsg("dialog cloased")
                                }
                            }} >

                        </CardDetailsDialog>

                    </Elements>
                )
            }


            <div className='parent-profile'>

                <Card className='user-details-container' >

                    <div style={{ position: 'relative' }}>
                        <img className='profile-img rounded-circle' src={
                            getProfilePicture(profileData.profilePic, profileData.gender)
                        }>
                        </img>


                        {/* <LazyLoadImage
        src={Constants.PROTOCOL_PLUS_HOST + profileData.profilePic}
        width={100}
        height={100}

        placeholderSrc={(profileData.gender === "male") ? defaultMale : defaultFemale}
    /> */}





                        <FileUpload style={{ position: 'absolute', right: '0', bottom: '0' }} selectedFile={uploadToServer} />
                    </div>


                    <div className='subparent-user-details-container'>
                        <div className='parent-username-profile values-profile'>
                            <p className='title-profile text-color-primary'> Username : </p>
                            <p className='value-profile text-color-primary'> {profileData.userName}</p>
                        </div>

                        <div className='parent-mobile-profile values-profile'>
                            <p className='title-profile text-color-primary'> Mobile : </p>

                            <div className='parent-needs-verification-profile'>
                                <p className='title-needs-verification-profile value-profile text-color-primary'> {profileData.mobile}</p>

                                <img id="update-mobile-anchor-element" className='update-icon-profile verification-or-update-icon-profile' src={editIcon} onClick={e => {
                                    e.preventDefault()
                                    logMsg('got clicked')
                                    handleVerificationIconClick(VERIFICATION_ICONs.MOBILE)
                                }}>

                                </img>
                                <Tooltip anchorId="update-mobile-anchor-element" content={profileData.isMobileVerified ? "Update Your Mobile" : "Your Mobile Number is not verified, click to verify"} place="right" />

                            </div>

                        </div>
                        <div className='parent-email-profile values-profile'>
                            <p className='title-profile text-color-primary'> Email : </p>



                            <div className='parent-needs-verification-profile'>
                                <p className='title-needs-verification-profile value-profile text-color-primary'> {profileData.email}</p>


                                <img id="update-email-anchor-element" className='update-icon-profile verification-or-update-icon-profile' src={editIcon} onClick={e => {
                                    e.preventDefault()
                                    logMsg('got clicked')
                                    handleVerificationIconClick(VERIFICATION_ICONs.EMAIL)
                                }}>
                                </img>
                                <Tooltip anchorId="update-email-anchor-element" content={profileData.isEmailVerified ? "Update Your Email" : "Your Email is not verified, click to verify"} place="right" />


                            </div>
                        </div>

                        <div className='parent-age-profile values-profile'>
                            <p className='title-profile text-color-primary'> Age : </p>
                            <p className='value-profile text-color-primary'> {profileData.age}</p>
                        </div>
                    </div>


                </Card>

                <Card className='bank-account-container' >

                    {

                        (bankData.bankAccountName) ?
                            <div>

                                <div className='parent-name-profile values-profile'>
                                    <p className='title-profile text-color-primary'> Name : </p>
                                    <p className='value-profile text-color-primary'> {bankData.bankAccountName}</p>
                                </div>

                                <div className='parent-account-profile values-profile'>
                                    <p className='title-profile text-color-primary'> Account No : </p>
                                    <p className='value-profile text-color-primary'> {bankData.bankAccountNumber}</p>
                                </div>
                                <div className='parent-ifsc-profile values-profile'>
                                    <p className='title-profile text-color-primary'> IFSC : </p>
                                    <p className='value-profile text-color-primary'> {bankData.bankAccountIfsc}</p>
                                </div>

                                <div className='parent-bankname-profile values-profile'>
                                    <p className='title-profile text-color-primary'> Bank Name : </p>
                                    <p className='value-profile text-color-primary'> {bankData.bankName}</p>
                                </div>

                                <div>

                                    <MyButton extras={{ "data_bs_toggle": "modal", "data_bs_target": "#exampleModal" }} onClick={() => {
                                        addBankDetailsDialogRef.current.openNow()
                                    }} btnValue={"Change Bank Account"} />
                                </div>


                            </div>

                            :


                            <div>
                                <div className='parent-bankname-profile values-profile'>
                                    <p className='title-profile text-color-primary'> Bank Details : </p>
                                    <p className='value-profile text-color-primary'> {"None"}</p>
                                </div>
                                <div>
                                    <MyButton extras={{ "data_bs_toggle": "modal", "data_bs_target": "#exampleModal" }} onClick={() => {
                                        addBankDetailsDialogRef.current.openNow()
                                    }} btnValue={"Add Bank Account"} />
                                </div>


                            </div>


                    }


                    {
                        <AddBankDetailsDialog ref={addBankDetailsDialogRef} />
                    }


                </Card>


                <Card className='card-container' >

                    {

                        (false) ?
                            <div>

                                <div className='parent-name-profile values-profile'>
                                    <p className='title-profile text-color-primary'> Card Number : </p>
                                    <p className='value-profile text-color-primary'> {bankData.bankAccountName}</p>
                                </div>

                                <div>

                                    <MyButton onClick={() => {
                                        createPaymentIntent()

                                    }} btnValue={"Update Card Details"} />
                                </div>


                            </div>

                            :


                            <div>
                                <div className='parent-bankname-profile values-profile'>
                                    <p className='title-profile text-color-primary'> Card Details : </p>
                                    <p className='value-profile text-color-primary'> {"None"}</p>
                                </div>
                                <div>
                                    <MyButton onClick={() => {
                                        addCardDetails()
                                    }} btnValue={"Add Card Details"} />
                                </div>


                            </div>


                    }



                </Card>


                <Card className='paypal-container' >

                    {

                        (false) ?
                            <div>

                                <div className='parent-name-profile values-profile'>
                                    <p className='title-profile text-color-primary'> PayPal Id : </p>
                                    <p className='value-profile text-color-primary'> {bankData.bankAccountName}</p>
                                </div>

                                <div>

                                    <MyButton onClick={() => {
                                        addOpenDataDialogCommand({ isOpen: true })
                                    }} btnValue={"Update PayPal Details"} />
                                </div>


                            </div>

                            :


                            <div>
                                <div className='parent-bankname-profile values-profile'>
                                    <p className='title-profile text-color-primary'> PayPal Details : </p>
                                    <p className='value-profile text-color-primary'> {"None"}</p>
                                </div>
                                <div>
                                    <MyButton onClick={() => {
                                        addOpenDataDialogCommand({ isOpen: true })
                                    }} btnValue={"Add PayPal Details"} />
                                </div>


                            </div>


                    }



                </Card>


                {
                    isLoading ?
                        <Loader />
                        :
                        <></>
                }
            </div>

        </div>

    )
}

export default Profile