import React from "react";
import './Card.css'


const Card = (props) => {
    const classes = 'card-component ' + props.className;

    let width = "auto"
    if (props.width) {
        width = props.width
    }
    let height = "auto"
    if (props.height) {
        height = props.height
    }

    return <div className={classes} style={{ width: width, height: height }}>{props.children}</div>
}

export default Card


