import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";


import Constants from "../utils/Constants.js";
import AxiosBase, { ErrorHandler } from "../axios/AxiosBase.js";
import Loader from "../loader/Loader.js";
import { logMsg } from "../utils/Utils.js";

// import { UserContext } from "../App.js";
import { useSelector, useDispatch } from "react-redux";
import { addUserAction } from "../../src/redux-toolkit/UserSlice.js";
import store from "../redux-toolkit/store.js";
import CustomDialog from "../utils/CustomDialog.js";

const Logout = () => {
    // const { userStateContext, contextDispather } = useContext(UserContext)

    const userStateRedux = useSelector((state) => state.userReducer)
    const reduxDispather = useDispatch()

    const [isLoading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [openDialogCommand, setOpenDialogCommand] = useState(Constants.DIALOG_DEFAULT_STATE)
   
    const addOpenDialogCommand = useCallback(({ msg = "", task = Constants.DIALOG_TASKS.NONE, isOpen = false, isShowCancelButton = false }) => {
        setOpenDialogCommand({ message: msg, task: task, isOpen: isOpen, isShowCancelButton: isShowCancelButton })
    }, [openDialogCommand])
    async function callLogoutApi() {
        logMsg("inside logout api function")

        try {
            setLoading(true)
            const reqData = { userId: userStateRedux._id }
            const options = {
                method: 'PUT',
                data: reqData,
                url: "/logout"

            };

            const res = await AxiosBase(options)
            const data = res.data


            logMsg(JSON.stringify(data))

            const userData = { isLoggedIn: false }
            // contextDispather({ type: Constants.USER, payload: userData })
            reduxDispather(addUserAction(userData))
            store.dispatch({
                type: "USER_LOGOUT"
            })

            navigate('/login')

        } catch (error) {
            const msg = ErrorHandler(error)
            addOpenDialogCommand({ msg:msg,isOpen: true })

        } finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        logMsg("calling use effect")
        callLogoutApi()
    }, [])

    return (

        <div className="alert alert-primary" role="alert">
            <CustomDialog
                dialogParams={openDialogCommand}

                onClickDialogCrossBtn={e => {
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogCancelBtn={e => {

                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogOkayBtn={async e => {
                    switch (openDialogCommand.task) {
                        case Constants.DIALOG_TASKS.GO_TO_LOGIN:
                            navigate('/login')
                            break;

                        default:
                            break;

                    }
                    addOpenDialogCommand({ isOpen: false })

                }}
                dialogStatusCallback={(isDialogOpened) => {
                    if (isDialogOpened) {

                    } else {

                    }
                }} >

            </CustomDialog>
            <h2>Logging Out</h2>
            {isLoading ? <Loader /> : <></>}
        </div>

    )
}

export default Logout