import { Component } from "react";

const MAX_TIMER=10

class TimerModule extends Component {

    isTimerRunning = false

    remainingTime = MAX_TIMER

    startTimer(callback) {
        
        this.isTimerRunning = true
        this.interval = setInterval(() => {

            --this.remainingTime

            callback(this.remainingTime)

            if (this.remainingTime < 1) {
                this.stopTimer()
            }

        }, 1000)
    }

    stopTimer() {
        this.remainingTime = MAX_TIMER
        clearInterval(this.interval)
        this.isTimerRunning = false
    }

    getRemainingTime() {
        return this.remainingTime
    }


    start
    render() {
        return (
            <div>

            </div>
        )
    }
}
export default TimerModule

