import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../reducer/UseReducer";

const verificationSlice = createSlice({
    name: 'verification_slice',
    initialState: {
        isEmailVerified: false,
        isMobileVerified: false
    },
    reducers: {
        setEmailVerified(state, newState) {
            initialState.isEmailVerified = newState.payload.isEmailVerified
        },

        setMobileVerified(state, newState) {
            initialState.isMobileVerified = newState.payload.isMobileVerified
        }
    }
})

export const { setEmailVerified, setMobileVerified } = verificationSlice.actions

export default verificationSlice.reducer