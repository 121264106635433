

import React from "react";

const TermsAndConditions = () => {

    return (

        <div className='parent-home'>
            <iframe className='iframe_home' src='terms.html' />
        </div>

    )
}

export default TermsAndConditions