import React, { useState } from 'react';
import './TicTacToe.css';

function TicTacToe() {
    const [board, setBoard] = useState(Array(9).fill(null));
    const [xIsNext, setXIsNext] = useState(true);

    const winner = calculateWinner(board);
    const currentPlayer = xIsNext ? 'X' : 'O';

    function handleClick(index) {
        if (board[index] || winner) return;

        const newBoard = board.slice();
        newBoard[index] = currentPlayer;
        setBoard(newBoard);
        setXIsNext(!xIsNext);
    }

    function renderSquare(index) {
        return (
            <button className={`square ${board[index]}`} onClick={() => handleClick(index)}>
                {board[index]}
            </button>
        );
    }
    function restartGame() {
        setBoard(Array(9).fill(null));
        setXIsNext(true);
    }

    return (
        <div className='tic-tac-toe-parent'>
            <h2>Tic-Tac-Toe</h2>
            <div className="board">
                {board.map((_, i) => renderSquare(i))}
            </div>
            <div className="status">
                {winner ? `Winner: ${winner}` : `Next player: ${currentPlayer}`}
            </div>
            <button onClick={restartGame}>Restart Game</button>
        </div>
    );
}

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];

    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }

    return null;
}

export default TicTacToe;
