import './EmailVerification.css'
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import MyEditBox from '../widgets/MyEditBox.js';
import Loader from "../loader/Loader.js";
import Constants from '../utils/Constants.js'
import editIcon from '../res/edit_black_icon.png'




import MyButton from '../widgets/MyButton.js';
import MyCheckbox from '../widgets/MyCheckbox.js';
import AxiosBase, { ErrorHandler } from '../axios/AxiosBase.js';
import { logMsg, logMsgExtra } from '../utils/Utils.js';

// import { UserContext } from "../App.js";
import { useSelector, useDispatch } from "react-redux";
import { addUserAction } from '../redux-toolkit/UserSlice.js';


import app from "../firebase/firebase.config.js";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { isValidEmail } from '../auth/Validation';
import MyEditIcon from '../widgets/MyEditIcon';
import CustomDialog from '../utils/CustomDialog';

const EmailVerification = () => {
    // const { state, dispatch } = useContext(UserContext)

    const reduxDispatch = useDispatch()
    const { dfdfd } = useSelector(state => state.userReducer)


    const navigate = useNavigate()
    const location = useLocation()

    const userIdPassed = location.state.userId
    const emailPassed = location.state.email

  
    logMsg(`userId_obtained ${userIdPassed}`)
    logMsg(`email_obtained ${emailPassed}`)

    let [user, setUserData] = useState({ userId: userIdPassed, email: emailPassed })
    const [isLoading, setLoading] = useState(false)
    const [isEmailEditable, setEmailEditable] = useState(emailPassed ? false : true)
   
    const [openDialogCommand, setOpenDialogCommand] = useState(Constants.DIALOG_DEFAULT_STATE)
   
    const addOpenDialogCommand = useCallback(({ msg = "", task = Constants.DIALOG_TASKS.NONE, isOpen = false, isShowCancelButton = false }) => {
        setOpenDialogCommand({ message: msg, task: task, isOpen: isOpen, isShowCancelButton: isShowCancelButton })
    }, [openDialogCommand])
   
    function getKeyAndValue(key, value) {
        user = { ...user, [key]: value }
        logMsgExtra("object is = ", user)
        setUserData(user)
    }


    async function sendEmailOTP() {

        if (

            isValidEmail(user.email)) {

            try {
                setLoading(true)

                const reqData = user

                const options = {
                    method: 'POST',
                    data: reqData,
                    url: "/sendning_email_otp_for_profile_update"

                };

                const res = await AxiosBase(options)
                const data = res.data

                logMsg(`${data}`)

                navigate('/verify_email_otp', { state: user })


            } catch (error) {
                const msg = ErrorHandler(error)
    
                addOpenDialogCommand({ msg:msg,isOpen: true })

            } finally {
                setLoading(false)
            }

        }

    }



    function handleSubmit(event) {
        event.preventDefault()

        setTimeout(() => {
            navigate('/verify_email_otp', { state: user })
        }, 2000)

        // sendEmailOTP()



    }
    function handleEditIconClick(e) {
        e.preventDefault()

        if (isEmailEditable) {
            setEmailEditable(false)
        } else {
            setEmailEditable(true)
        }
    }

    return (
        <div className='root-email-verification'>
              <CustomDialog
                dialogParams={openDialogCommand}

                onClickDialogCrossBtn={e => {
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogCancelBtn={e => {

                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogOkayBtn={async e => {
                    switch (openDialogCommand.task) {
                        case Constants.DIALOG_TASKS.GO_TO_LOGIN:
                            navigate('/login')
                            break;

                     
                        default:

                            break;
                    }
                    addOpenDialogCommand({ isOpen: false })

                }}
                dialogStatusCallback={(isDialogOpened) => {
                    if (isDialogOpened) {

                    } else {

                    }
                }} >

            </CustomDialog>
            <form className='form-parent-email-verification' onSubmit={handleSubmit}>
                <p className="intro-email-verification text-color-primary">Verify email</p>

                <div className='parent-edit-mob-num-email-verification'>
                    <MyEditBox fieldValueObtained={getKeyAndValue} type={"email"} keyName={"email"} defaultValue={"Enter Email"}
                        value={user.email} disabled={isEmailEditable ? false : true}
                        editIcon={editIcon} editIconPosition={'right'} editIconClick={handleEditIconClick} hideEditIcon={false}
                        image={"account.webp"}></MyEditBox>

                    <MyEditIcon src={editIcon} position={'right'}
                        onClick={handleEditIconClick} hidden={false}>

                    </MyEditIcon>

                </div>


                <MyButton btnValue={"Submit"} />

            </form>
            {
                isLoading ? <Loader /> : <></>
            }


            <div className='captha' id="recaptcha-container">

            </div>

        </div>
    )

}

export default EmailVerification