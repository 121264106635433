import './Settings.css'
import React from 'react'
import Card from '../ui/Card'
import { useNavigate } from 'react-router-dom'


export default function Settings() {
    const features = ["Profile", "Currency", "App Version - 1.0", "Logout"]

    const navigate = useNavigate()

    function clickMe(item) {
        switch (item) {
            case features[0]:
                navigate("/profile")
                break;
            case features[1]:
                navigate("/currency")
                break;
            case features[3]:
                navigate('/logout')
                break;
            default:
                break
        }
    }
    return (
        <div className='parent-settings'>

            {
                features.map(item => (
                    <div className='parent-item-settings' onClick={(e) => {
                        e.preventDefault()
                        clickMe(item)

                    }}>
                        <Card className='item-settings'>
                            <p className='text-color-primary text-item-settings'>{item}</p>
                        </Card>
                    </div>

                ))
            }
        </div>
    )
}
