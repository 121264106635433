import Constants from "./Constants";
import defaultMale from '../res/default_male.jpg'
import defaultFemale from '../res/default_female.png'

export function getLanguage() {
    return (navigator.language || navigator.geolocation[0])
}

export function getPaymentGatewayAmount(amountInUSDollar, currency) {

    switch (currency) {
        case Constants.CURRENCY.INR:
            return getAmountTwoDecimals(amountInUSDollar * Constants.US_DOLLAR_CONVERSION_RATE)
            break
        case Constants.CURRENCY.USD:
            return getAmountTwoDecimals(amountInUSDollar)
            break
        default:
            return getAmountTwoDecimals(amountInUSDollar)
            break
    }

}

export function getAmountWithCurrency(amountInUSDollar, currency) {

    switch (currency) {
        case Constants.CURRENCY.INR:
            return getAmountTwoDecimals(amountInUSDollar * Constants.US_DOLLAR_CONVERSION_RATE) + " " + Constants.CURRENCY_SYMBOL.INR
            break
        case Constants.CURRENCY.USD:
            return getAmountTwoDecimals(amountInUSDollar) + " " + Constants.CURRENCY_SYMBOL.USD
            break
        default:
            return getAmountTwoDecimals(amountInUSDollar)
            break
    }

}

export function getAmountWithoutCurrency(amountInUSDollar, currency) {

    switch (currency) {
        case Constants.CURRENCY.INR:
            return getAmountTwoDecimals(amountInUSDollar * Constants.US_DOLLAR_CONVERSION_RATE)
            break
        case Constants.CURRENCY.USD:
            return getAmountTwoDecimals(amountInUSDollar)
            break
        default:
            return getAmountTwoDecimals(amountInUSDollar)
            break
    }

}
export function getCurrencySymbol(currency) {

    switch (currency) {
        case Constants.CURRENCY.INR:
            return Constants.CURRENCY_SYMBOL.INR
            break
        case Constants.CURRENCY.USD:
            return Constants.CURRENCY_SYMBOL.USD
            break
        default:
            return Constants.CURRENCY_SYMBOL.USD
            break
    }

}
export function getFreeContestPoints(amountInUSDollar) {
    return getAmountTwoDecimals(amountInUSDollar * Constants.FREE_POINTS_CONVERSION_RATE)

}

export function getRemainingSubscriptionDays(amountInUSDollar) {

    return Math.floor(getAmountTwoDecimals(amountInUSDollar * Constants.US_DOLLAR_CONVERSION_RATE))

}


export const getFormattedDateAndTime = (dateInStr) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];


    const dateObj = new Date(dateInStr);
    const yyyy = dateObj.getFullYear();
    let mm = dateObj.getMonth(); // Months start at 0!
    let dd = dateObj.getDate();

    if (dd < 10) dd = '0' + dd;


    // const hours = dateObj.getHours()
    // const mins = dateObj.getMinutes()
    // const seconds = dateObj.getSeconds()


    const formattedTodays = `${dd}-${months[mm]}-${yyyy}, ${formatToAmPM(dateObj)}`

    return formattedTodays
}


function formatToAmPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const getFormattedTime = (seconds) => {

    let hours = Math.floor((seconds / (60 * 60)) % 24)
    let mins = Math.floor((seconds / 60) % 60)
    let secs = Math.floor(seconds % 60)

    let formatted
    if (hours == 0) {
        if (mins == 0) {
            formatted = `${secs} s`
        } else {
            formatted = `${(mins)} m ${(secs)} s`
        }
    } else {
        formatted = `${(hours)} h ${(mins)} m ${(secs)} s`
    }

    return formatted
}

// const addZeroIfSingleDigit = (value) => {

//     return (value < 10 ? '0' + value : value)
// }

export function alertMsg(msg) {
    logMsg(msg)
    alert(msg)
}
export function logMsg(msg) {
    console.log(msg)
}
export function logMsgExtra(prefix, msg) {
    console.log(prefix, msg)
}

export function getMinsFromSeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const secondsRemaining = Math.floor(seconds % 60);

    const addExtraZeroInMins = minutes.toString().length === 1 ? ("0" + minutes) : minutes
    const addExtraZeroInSeconds = secondsRemaining.toString().length === 1 ? ("0" + secondsRemaining) : secondsRemaining
    return addExtraZeroInMins + ":" + addExtraZeroInSeconds

}

export function isValidIFSC(ifscCode) {

    // Regex to check valid
    // ifsc_Code 

}

export function getLastSunday() {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
    return lastSunday
}
export function getAmountTwoDecimals(value) {
    return (Math.round(value * 100) / 100).toFixed(2);
}

export function getSecondsFromDays(noOfDays) {
    return noOfDays * 24 * 60 * 60
}

export function evaluateSessionTime(subscriptionBalance) {
    return subscriptionBalance * 24 * 60 * 60
}

export function evaluateSubscriptionBalance(subscriptionTime) {
    return subscriptionTime / (24 * 60 * 60)
}

export function getProfilePicture(profilePic, gender) {
    if (profilePic) {
        return Constants.PROTOCOL_PLUS_HOST + profilePic
    }

    return (gender === "male") ? defaultMale : defaultFemale
}
