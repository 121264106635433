import React from "react";
import "./BollywoodList.css";
import demoImage from '../../../res/demo_image.jpg'
// Array of movie images, each with specific grid positioning
const movieImages = [
    { src: demoImage, gridColumn: '1 / span 2', gridRow: '1 / span 2' }, // Wider image
    { src: demoImage, gridColumn: '3 / span 1', gridRow: '1 / span 1' }, // Normal image
    { src: demoImage, gridColumn: '3 / span 1', gridRow: '2 / span 1' },
    { src: demoImage, gridColumn: '4 / span 1', gridRow: '1 / span 2' }, // Taller image
    { src: demoImage, gridColumn: '1 / span 1', gridRow: '3 / span 1' },
    { src: demoImage, gridColumn: '2 / span 1', gridRow: '3 / span 1' },
    { src: demoImage, gridColumn: '3 / span 1', gridRow: '3 / span 2' }, // Taller image
    { src: demoImage, gridColumn: '4 / span 1', gridRow: '3 / span 1' },
    { src: demoImage, gridColumn: '4 / span 1', gridRow: '4 / span 1' },
    { src: demoImage, gridColumn: '1 / span 2', gridRow: '4 / span 2' }, // Wide image
    { src: demoImage, gridColumn: '3 / span 2', gridRow: '5 / span 1    ' },

];

function BollywoodList() {
    return (
        <div className="bollywood-parent">
            <div className="grid-container">
                {movieImages.map((image, index) => (
                    <div
                        className="movie-card"
                        style={{ gridColumn: image.gridColumn, gridRow: image.gridRow }}
                        key={index}
                    >
                        <img src={image.src} alt={`Movie ${index + 1}`} />
                    </div>
                ))}
            </div>


            <div className="start-btn-bollywood-parent">
                <button className="start-btn-bollywood">
                    Start Watching
                </button>
            </div>

        </div>
    );
}

export default BollywoodList