import React from 'react';
import './Services.css';
import {  useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate()
  return (

    <main className='main-services'>
      <section className="section">
        <h2 onClick={() => { navigate('/game_intro') }}>Exports</h2>
        <p>
        We are a leading export company committed to delivering high-quality products from India to markets worldwide. With a strong foundation in international trade, we specialize in providing top-tier products across diverse industries, ensuring that our clients receive only the best. Our team is driven by a passion for excellence, and we take pride in maintaining the highest standards of service, compliance, and innovation.
        </p>
        <h3>Our Journey</h3>
        <ul>
          <li><strong>Our Mission:</strong> Our mission is to bridge the gap between global demand and India’s rich supply of quality products. We aim to support economic growth by promoting India’s export capabilities while building long-lasting relationships with clients and partners across the globe. Through ethical business practices, transparency, and a customer-centric approach, we strive to be your trusted partner in global trade</li>
          <li><strong>What We Export:</strong> We specialize in exporting a wide range of products tailored to the needs of various industries. Whether it’s agricultural goods, textiles, machinery, pharmaceuticals, or electronics, our portfolio is diverse and adaptable. Each product undergoes rigorous quality checks to ensure that it meets international standards and customer expectations. We are also dedicated to expanding our offerings based on market trends and customer feedback.</li>
        
          <li><strong>Global Reach:</strong> With a vast network of clients and partners spread across the world, we have established a strong presence in international markets. Our expertise in logistics, compliance with export regulations, and strategic alliances with shipping companies ensure timely and hassle-free delivery to any corner of the globe. Whether you’re in North America, Europe, Asia, or Africa, [Your Company Name] is equipped to serve your export needs.</li>
          <li><strong>Why Choose Us:</strong> Choosing [Your Company Name] means partnering with a company that values quality, reliability, and customer satisfaction above all. We offer competitive pricing, seamless export processes, and comprehensive after-sales support to make your experience as smooth as possible. Our commitment to sustainability and ethical trade further distinguishes us in the global marketplace, ensuring that our business practices positively impact people and the planet.</li>
        </ul>
        <h3>Upcoming Products</h3>
        <p>
          Keep an eye out for exciting new releases on the new exciting products.
        </p>
      </section>

      <section className="section">
        <h2 onClick={() => { navigate('/watch_intro') }}>Imports</h2>
        <p>
        We are a dynamic import company specializing in bringing the finest international products to the Indian market. With a strong presence in global trade, we are committed to sourcing high-quality goods from trusted suppliers worldwide. Our expertise spans across various industries, ensuring that our clients have access to the latest and best products from around the globe.
        </p>
        <h3>Our Journey</h3>
        <ul>
          <li><strong>Our Mission: </strong> Our mission is to connect India with global markets by importing superior products that meet the evolving needs of businesses and consumers. We aim to foster growth and innovation by making world-class goods easily accessible in India. Through responsible sourcing, strategic partnerships, and a dedication to quality, we strive to enhance the Indian market’s diversity and competitiveness.</li>
          <li><strong>What We Import: </strong>We import a wide array of products across various sectors, including electronics, machinery, raw materials, consumer goods, and luxury items. Each product is carefully selected to meet the highest standards of quality and performance. Our broad supplier network allows us to source specialized goods based on our clients' needs, ensuring flexibility and customization in our offerings.</li>
          <li><strong>Global Sourcing Network: </strong> Our global network of trusted suppliers spans multiple continents, including North America, Europe, Asia, and beyond. We have cultivated strong relationships with manufacturers and distributors worldwide, allowing us to efficiently source and deliver products that meet the demands of the Indian market. Our expertise in logistics and regulatory compliance ensures smooth import processes, regardless of the product's origin.</li>
          <li><strong>HWhy Choose Us: </strong>At [Your Company Name], we are dedicated to providing our clients with unparalleled service, competitive pricing, and seamless import experiences. Our commitment to quality control, transparency, and timely deliveries sets us apart in the market. Whether you’re looking for bulk imports or specialized products, we offer comprehensive solutions to meet your needs, backed by a strong focus on customer satisfaction and ethical business practices.</li>
      
        </ul>
        <h3>Upcoming Products</h3>
        <p>
        Keep an eye out for exciting new releases on the new exciting products.
        </p>
      </section>

     
    </main>



  );
};

export default Services;
