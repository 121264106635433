import React from 'react';

import Cube from '../cube/Cube.js';

const GameIntro = () => {
  return (
    <div className="game-intro">

      <Cube startText={"Start Playing"}/>

    </div>
  );
};

export default GameIntro;
