import {Link, useParams} from "react-router-dom";
import { logMsg } from "../utils/Utils.js";

const TransactionDetails = () => {
    logMsg("called")
    const {txid}=useParams()
    logMsg(txid)

    return (
        <div className="alert alert-primary" role="alert">
            <Link to={"/transactions/"+txid} > Transaction id is {txid}</Link>
        </div>

    )
}

export default TransactionDetails