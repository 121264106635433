import React, { useState, useEffect } from 'react';
import './SlidingPuzzle.css';

const SIZE = 4; // 4x4 grid

// Utility function to generate an array of tiles
const generatePuzzle = () => {
  const puzzle = Array.from({ length: SIZE * SIZE - 1 }, (_, i) => i + 1);
  puzzle.push(null); // Add the empty tile
  return puzzle;
};

// Utility function to shuffle the puzzle tiles
const shufflePuzzle = (puzzle) => {
  let shuffledPuzzle = [...puzzle];
  for (let i = shuffledPuzzle.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledPuzzle[i], shuffledPuzzle[j]] = [shuffledPuzzle[j], shuffledPuzzle[i]];
  }
  return shuffledPuzzle;
};

// Check if the clicked tile is adjacent to the empty space
const canMove = (index, emptyIndex) => {
  const row = Math.floor(index / SIZE);
  const col = index % SIZE;
  const emptyRow = Math.floor(emptyIndex / SIZE);
  const emptyCol = emptyIndex % SIZE;
  return (Math.abs(row - emptyRow) === 1 && col === emptyCol) ||
         (Math.abs(col - emptyCol) === 1 && row === emptyRow);
};

function SlidingPuzzle() {
  const [puzzle, setPuzzle] = useState([]);
  const [emptyIndex, setEmptyIndex] = useState(SIZE * SIZE - 1);

  useEffect(() => {
    const initialPuzzle = generatePuzzle();
    const shuffledPuzzle = shufflePuzzle(initialPuzzle);
    setPuzzle(shuffledPuzzle);
    setEmptyIndex(shuffledPuzzle.indexOf(null));
  }, []);

  // Handle tile click and movement
  const handleTileClick = (index) => {
    if (canMove(index, emptyIndex)) {
      const newPuzzle = [...puzzle];
      [newPuzzle[index], newPuzzle[emptyIndex]] = [newPuzzle[emptyIndex], newPuzzle[index]]; // Swap tiles
      setPuzzle(newPuzzle);
      setEmptyIndex(index); // Update empty space
    }
  };

  // Check if the puzzle is solved
  const isSolved = () => {
    for (let i = 0; i < puzzle.length - 1; i++) {
      if (puzzle[i] !== i + 1) return false;
    }
    return true;
  };

  return (
    <div className="puzzle-container">
      <h2>{isSolved() ? "Puzzle Solved!" : "Sliding Puzzle"}</h2>
      <div className="puzzle-grid">
        {puzzle.map((tile, index) => (
          <div
            key={index}
            className={`tile ${tile === null ? "empty" : "filled"}`}
            onClick={() => handleTileClick(index)}
          >
            {tile}
          </div>
        ))}
      </div>
      <button onClick={() => window.location.reload()}>Shuffle and Restart</button>
    </div>
  );
}

export default SlidingPuzzle;
