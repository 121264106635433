import './MobileVerification.css'
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import MyEditBox from '../widgets/MyEditBox.js';
import Loader from "../loader/Loader.js";
import Constants from '../utils/Constants.js'
import editIcon from '../res/edit_black_icon.png'




import MyButton from '../widgets/MyButton.js';
import MyCheckbox from '../widgets/MyCheckbox.js';
import AxiosBase, { ErrorHandler } from '../axios/AxiosBase.js';
import { getMinsFromSeconds, logMsg, logMsgExtra } from '../utils/Utils.js';

// import { UserContext } from "../App.js";
import { useSelector, useDispatch } from "react-redux";
import { addUserAction } from '../redux-toolkit/UserSlice.js';


import app from "../firebase/firebase.config.js";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import MyEditIcon from '../widgets/MyEditIcon';
import TimerModule from '../utils/TimerModule';
import CustomDialog from '../utils/CustomDialog';

const MobileVerification = () => {
    // const { state, dispatch } = useContext(UserContext)

    const reduxDispatch = useDispatch()
    const { dfdfd } = useSelector(state => state.userReducer)


 
    const navigate = useNavigate()
    const location = useLocation()

    const userIdPassed = location.state.userId
    const mobilePassed = location.state.mobile


    logMsg(`userId_obtained ${userIdPassed}`)
    logMsg(`mobile_obtained ${mobilePassed}`)
    const RESEND_OTP_TIME = 10
    let [user, setUserData] = useState({ userId: userIdPassed, mobile: mobilePassed })
    const [isLoading, setLoading] = useState(false)
    let [isOTPSent, setOTPSent] = useState(false)
    const [isMobileEditable, setMobileEditable] = useState(user.mobile ? false : true)

    const timerObj = useRef(new TimerModule())
    const [resendOTPTime, setResendOTPTime] = useState(0)
    
    const [openDialogCommand, setOpenDialogCommand] = useState(Constants.DIALOG_DEFAULT_STATE)
   
    const addOpenDialogCommand = useCallback(({ msg = "", task = Constants.DIALOG_TASKS.NONE, isOpen = false, isShowCancelButton = false }) => {
        setOpenDialogCommand({ message: msg, task: task, isOpen: isOpen, isShowCancelButton: isShowCancelButton })
    }, [openDialogCommand])

    let auth = getAuth(app)

    function timerCallback(remainingTime) {
        logMsg(`remaining time = ${remainingTime}`)
        setResendOTPTime(remainingTime)
    }

   
    function getKeyAndValue(key, value) {
        user = { ...user, [key]: value }
        logMsgExtra("object is = ", user)
        setUserData(user)
    }

    // function verifyCaptha() {
    //     const auth = getAuth(app)
    //     console.log("dkfjdf")

    //     let some = new RecaptchaVerifier('recaptcha-container', {
    //         'size': 'checkbox',
    //         'callback': (response) => {
    //             console.log("dkfjdf")
    //             logMsg('verified')
    //             logMsg(`captcha verified successfully with id ${window.recaptchaWidgetId}`)
    //             window.recaptchaWidgetId = response.recaptchaWidgetId
    //             verifyPhoneNumber()
    //         },
    //         'expired-callback': () => {
    //             console.log("edkfjdf")
    //             logMsg(`captcha verification error`)
    //             window.recaptchaVerifier.reset(window.recaptchaWidgetId);
    //         }
    //     }, auth);

    //     logMsg(some)
    // }


    function verifyPhoneNumber() {

        setLoading(true)

        setTimeout(() => {
            setLoading(false)
            setOTPSent(true)
            timerObj.current.startTimer(timerCallback)
        }, 2000)


        // logMsg('calling verify captha')
        // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', { 'size': 'invisible' }, auth);


        // signInWithPhoneNumber(auth, Constants.COUNTRY_CODE + user.mobile, window.recaptchaVerifier)
        //     .then((confirmationResult) => {
        //         console.log('sing sent')
        //         // SMS sent. Prompt user to type the code from the message, then sign the
        //         // user in with confirmationResult.confirm(code).
        //         window.confirmationResult = confirmationResult;
        //         setOTPSent(true)
        //         setLoading(false)
        //         timerObj.current.startTimer(timerCallback)
        //     }).catch((error) => {
        //         setLoading(false)

        //         console.log(error)
        //         // grecaptcha.reset(window.recaptchaWidgetId);

        //         // // Or, if you haven't stored the widget ID:
        //         // window.recaptchaVerifier.render().then(function (widgetId) {
        //         //     grecaptcha.reset(widgetId);
        //         // });
        //     });

    }

    async function verifyMobileApiCall() {
        logMsg("calling verifyMobileApiCall")

        try {
            setLoading(true)
            const reqData = { userId: user.userId, mobile: user.mobile }
            const options = {
                method: 'PUT',
                data: reqData,
                url: "/mobile_verification"

            };

            const res = await AxiosBase(options)
            const data = res.data

            addOpenDialogCommand({ msg: "Your Mobile is Verified", task: Constants.DIALOG_TASKS.MOBILE_VERFIFIED, isOpen: true })

        } catch (error) {
            const msg = ErrorHandler(error)

            addOpenDialogCommand({ msg: msg, isOpen: true })

        } finally {
            setLoading(false)
        }

    }

    function handleSubmit(event) {
        event.preventDefault()

        if (!isOTPSent) {
            verifyPhoneNumber()
        } else {
            setLoading(true)
            window.confirmationResult.confirm(user.otp).then((result) => {
                // User signed in successfully.
                const user = result.user;
                console.log(user)
                setLoading(false)
                verifyMobileApiCall()

            }).catch((error) => {
                setLoading(false)
                console.log(error)
            });
        }
    }
    function handleEditIconClick(e) {
        e.preventDefault()

        logMsg('working')
        if (isMobileEditable) {
            setMobileEditable(false)
        } else {
            setMobileEditable(true)
        }
    }

    function handleResendOTP(e) {
        e.preventDefault()

        if (timerObj.current.isTimerRunning) {
            return
        }

        verifyPhoneNumber()
    }

    return (
        <div className='root-mobile-verification'>
            <CustomDialog
                dialogParams={openDialogCommand}

                onClickDialogCrossBtn={e => {
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogCancelBtn={e => {

                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogOkayBtn={async e => {
                    switch (openDialogCommand.task) {
                        case Constants.DIALOG_TASKS.GO_TO_LOGIN:
                            navigate('/login')
                            break;

                        case Constants.DIALOG_TASKS.MOBILE_VERFIFIED:
                            navigate(-1)
                            break;
                        default:

                            break;
                    }
                    addOpenDialogCommand({ isOpen: false })

                }}
                dialogStatusCallback={(isDialogOpened) => {
                    if (isDialogOpened) {

                    } else {

                    }
                }} >

            </CustomDialog>


            <form className='form-parent-mobile-verification' onSubmit={handleSubmit}>
                <p className="intro-mobile-verification text-color-primary">Verify Mobile</p>

                <div className='parent-edit-mob-num-mobile-verification'>
                    <MyEditBox fieldValueObtained={getKeyAndValue} type={"number"} keyName={"mobile"}
                        defaultValue={"Enter Mobile Number"} value={user.mobile} disabled={isMobileEditable}
                        hidden={isOTPSent} image={"account.webp"} >

                    </MyEditBox>
                    <MyEditIcon src={editIcon} position={'right'}
                        onClick={handleEditIconClick} hidden={isOTPSent}>

                    </MyEditIcon>


                </div>

                <div className='parent-otp-mobile-verification' hidden={!isOTPSent}>
                    <MyEditBox fieldValueObtained={getKeyAndValue} type={"number"} keyName={"otp"} defaultValue={"Enter OTP"}
                        value={user.otp} image={"account.webp"}></MyEditBox>

                    <p className='resend-otp-mobile-verification' onClick={handleResendOTP}><u>{"Resend OTP " + (timerObj.current.isTimerRunning ? (" " + getMinsFromSeconds(timerObj.current.remainingTime)) : " Now")}</u></p>

                </div>



                <MyButton btnValue={"Submit"} />

                {/* <p className='login-mobile-verification text-color-ternary'
                    onClick={() => {
                        logMsg("clicking")
                        navigate('/login')
                    }}>Back to Login</p> */}


            </form>
            {
                isLoading ? <Loader /> : <></>
            }


            <div className='captha' id="recaptcha-container">

            </div>

        </div>
    )

}

export default MobileVerification