import {Link, Outlet} from "react-router-dom";

const Transactions = () => {
    return (
        <div className="alert alert-primary" role="alert">

            <Link to="/transactions/" > Transactions</Link>
            <Outlet/>
        </div>

    )
}

export default Transactions