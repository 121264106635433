import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../reducer/UseReducer";
import Constants from "../utils/Constants";
import { logMsg } from "../utils/Utils";


export const configSlice = createSlice({
    name: 'config_slice',
    initialState: {
        isGameEnabled: false,
        isContestEnabled: false,
        currency: Constants.CURRENCY.USD
    },
    reducers: {
        setConfig: (state, newState) => {

            logMsg(`${state.sessionTime}`)

            state.isGameEnabled = newState.payload.isGameEnabled
            state.isContestEnabled = newState.payload.isContestEnabled

        },

        setCurrency: (state, newState) => {
            state.currency = newState.payload
        }

    }
})

export const { setConfig,setCurrency } = configSlice.actions

export default configSlice.reducer