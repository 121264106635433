import React, { useState } from "react";
import { logMsg } from "../utils/Utils.js";
import "./MyEditIcon.css"

function MyEditIcon(props) {


    let hidden = ((props.hidden === undefined) || (props.hidden === true)) ? true : false
    console.log(`some ${props.hidden}`)


    const editIconPositionStyle = props.position === "right" ? { right: '10px' } : { left: '10px' }

    const editIconFinalStyle = {
        width: '30px', height: '30px', position: 'absolute',
        bottom: '2px', ...editIconPositionStyle
    }

    return (

        <img className="edit-icon-my-edit-box" src={props.src} style={editIconFinalStyle} onClick={props.onClick} hidden={hidden} ></img>

    )
}

export default MyEditIcon
