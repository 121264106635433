
const OutsideIndiaOfficeDetails = () => {
   
    // logMsg(useParams().mname)
    return (

        <div >
            <h2>My Office Details outside India</h2>

        </div>

    )
}

export default OutsideIndiaOfficeDetails