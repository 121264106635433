import Hero from "../header/Hero.js";
import Services from "../header/Services.js";
import Footer from "../header/Footer.js";
import MyNav from "../header/MyNav";
import MyNavTest from "../header/MyNavTest.js";

const Home = () => {
    return (
        <div >
            <MyNav />
            <Hero />
            <Services />
            <Footer />
        </div>
    )
}

export default Home;