import React from 'react';

import Cube from '../cube/Cube.js';

const WatchIntro = () => {
  return (
    <div className="watch-intro">

      <Cube startText={"Start Watching"} />

    </div>
  );
};

export default WatchIntro;