

import React from "react";

const PrivacyAndPolicy = () => {

    return (

        <div className='parent-home'>
            <iframe className='iframe_home' src='privacy_policy.html' />
        </div>

    )
}

export default PrivacyAndPolicy