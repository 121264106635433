import React, { useState } from "react";
import { logMsg } from "../utils/Utils.js";
import "./MyEditBox.css"

function MyEditBox(props) {

    let disabled = false
    if (props.disabled === undefined) {
        disabled = false
    } else {
        disabled = props.disabled
    }

    let hidden = false
    if (props.hidden === undefined) {
        hidden = false
    } else {
        hidden = props.hidden
    }

    logMsg(`is disabled = ${disabled}`)
    const changeHandler = (event) => {

        const k = event.target.name
        const v = event.target.value
        logMsg(`key is ${k} and value is ${v}`)

        props.fieldValueObtained(k, v)

    }



    return (
        <div className="parent-input">
            <input type={props.type} className={"input-edit-box edit-box-component " + props.customClass}
                name={props.keyName} placeholder={props.defaultValue} value={props.value} disabled={disabled} hidden={hidden} onChange={changeHandler} />
        </div>
    )
}

export default MyEditBox
