import React, { useState, useEffect } from 'react';
import './MemoryGame.css';

const initialCards = [
  { id: 1, value: '🍎', flipped: false, matched: false },
  { id: 2, value: '🍌', flipped: false, matched: false },
  { id: 3, value: '🍇', flipped: false, matched: false },
  { id: 4, value: '🍓', flipped: false, matched: false },
  { id: 5, value: '🍍', flipped: false, matched: false },
  { id: 6, value: '🍉', flipped: false, matched: false },
  { id: 7, value: '🍒', flipped: false, matched: false },
  { id: 8, value: '🍋', flipped: false, matched: false },
];

const shuffleCards = (cards) => {
  return [...cards, ...cards]
    .sort(() => Math.random() - 0.5)
    .map((card) => ({ ...card, id: Math.random() }));
};

const MemoryGame = () => {
  const [cards, setCards] = useState(shuffleCards(initialCards));
  const [flippedCards, setFlippedCards] = useState([]);
  const [moves, setMoves] = useState(0);
  const [matchedPairs, setMatchedPairs] = useState([]);

  const handleFlip = (index) => {
    if (flippedCards.length === 2) return;

    const newCards = [...cards];
    newCards[index].flipped = true;
    setCards(newCards);
    setFlippedCards([...flippedCards, { ...newCards[index], index }]);
  };

  useEffect(() => {
    if (flippedCards.length === 2) {
      const [firstCard, secondCard] = flippedCards;
      setMoves(moves + 1);

      if (firstCard.value === secondCard.value) {
        const newCards = [...cards];
        newCards[firstCard.index].matched = true;
        newCards[secondCard.index].matched = true;
        setCards(newCards);
        setMatchedPairs([...matchedPairs, firstCard.value]);
      } else {
        setTimeout(() => {
          const newCards = [...cards];
          newCards[firstCard.index].flipped = false;
          newCards[secondCard.index].flipped = false;
          setCards(newCards);
        }, 1000);
      }
      setFlippedCards([]);
    }
  }, [flippedCards, cards, matchedPairs, moves]);

  const resetGame = () => {
    setCards(shuffleCards(initialCards));
    setFlippedCards([]);
    setMoves(0);
    setMatchedPairs([]);
  };

  return (
    <div className="memory-game-container">
      <h1>Memory Matching Game</h1>
      <p>Moves: {moves}</p>
      <p>Matched Pairs: {matchedPairs.length}</p>
      <button onClick={resetGame}>Reset Game</button>

      <div className="board">
        {cards.map((card, index) => (
          <div
            key={card.id}
            className={`card ${card.flipped || card.matched ? 'flipped' : ''}`}
            onClick={() => !card.flipped && !card.matched && handleFlip(index)}
          >
            <div className="card-inner">
              <div className="card-front">?</div>
              <div className="card-back">{card.value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemoryGame;
