import {Link, useParams} from "react-router-dom";

const ContactDetailsOffice = () => {

    // logMsg(useParams().mname)
    return (

        <div >
            <h2>I am from office details</h2>


            <ul>
                <li>
                    <Link to="/contact/office/inside-india">office inside india</Link>
                </li>

                <li>
                    <Link to="/contact/office/outside-india">office outside india</Link>
                </li>
            </ul>
        </div>



    )
}

export default ContactDetailsOffice