import React from 'react';
import './Hero.css';
import ViewPager from './ViewPager.js';


const Hero = () => {
  return (
    <div className='container-hero'>
      <ViewPager />
      {/* <div className='intro-hero'>
        <h1 >Welcome to Auropine</h1><br></br>
      </div> */}

    </div>

  );
};

export default Hero;
