// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyCDkRAFFy7r23Wvq0ARggq5pNNjXEzruFM",
  // authDomain: "auropine-apps.firebaseapp.com",
  // projectId: "auropine-apps",
  // storageBucket: "auropine-apps.appspot.com",
  // messagingSenderId: "372014108160",
  // appId: "1:372014108160:web:f087b3bcee9d5a0e4712e5",
  // measurementId: "G-TKTYPENVPH"


  apiKey: "AIzaSyD1ERMuqiiUwFA65zZdnxxZMh7RiKorFSk",
  authDomain: "test-proj-e3d90.firebaseapp.com",
  projectId: "test-proj-e3d90",
  storageBucket: "test-proj-e3d90.appspot.com",
  messagingSenderId: "587452158639",
  appId: "1:587452158639:web:e1ae3cd51b96a9f266fa5a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export default app
