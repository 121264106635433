class Constants {
  static get MENU_ITEMS() {
    return {
      ABOUT_US: "About Us",
      TERMS: "Terms & Conditions",
      PRIVACY: "Privacy Policy",
      SOCIAL_MEDIA: "Social Media",
      CONTACT_US: "Contact Us",
      COMPANY: "Company",
      TIC_TAC_TOE: "Tic Tac Toe",
      SLIDING_NUMBER: "Sliding Number",
      CAR_RACING: "Car Racing",
      MEMORY_GAME: "Memory Game",
      GUESS_WORD: "Guess The Word",

      HOLLYWOOD: "Hollywood",
      BOLLYWOOD: "Bollywood",
    };
  }
  static get COUNTRY_CODE() {
    return "+91";
  }
  static get BASE_URL() {
    return this.PROTOCOL_PLUS_HOST + "api/v1";
  }

  static get PROTOCOL_PLUS_HOST() {
    // return "http://localhost:4000/";
    return "https://apis.auropine.com/";
  }

  static get US_DOLLAR_CONVERSION_RATE() {
    return 80;
  }
  static get FREE_POINTS_CONVERSION_RATE() {
    return 80;
  }

  static get PRODUCT_CATEGORIES() {
    return [
      {
        _id: 0,
        name: this.PRODUCTS.GAME,
      },
      {
        _id: 1,
        name: this.PRODUCTS.CONTEST,
      },
    ];
  }

  static get CURRENCY() {
    return { USD: "USD", INR: "INR" };
  }
  static get CURRENCY_SYMBOL() {
    return { USD: "$", INR: "Rs" };
  }

  static get DIALOG_TASKS() {
    return {
      NONE: "NONE",
      GO_TO_LOGIN: "GO_TO_LOGIN",
      GO_TO_CONTEST_LIST: "GO_TO_CONTEST_LIST",
      EMAIL_VERIFIED: "EMAIL_VERIFIED",
      DEDUCT_MONEY: "DEDUCT_MONEY",
      GO_TO_PAYMENT_RAZORPAY: "GO_TO_PAYMENT_RAZORPAY",
      GO_TO_PROFILE: "GO_TO_PROFILE",
    };
  }
  static get DIALOG_DEFAULT_STATE() {
    return {
      message: "",
      task: this.DIALOG_TASKS.NONE,
      isOpen: false,
      isShowCancelButton: false,
    };
  }

  static get PAYMENT_SUCCESS() {
    return "PAYMENT_SUCCESS";
  }
  static get PAYMENT_FAILED() {
    return "PAYMENT_FAILED";
  }

  static get NAVIGATION() {
    return { PLAY: "PLAY", WINNERS: "WINNERS", SCORE: "SCORE" };
  }

  static get PRODUCTS() {
    return { GAME: "Games", CONTEST: "Contests" };
  }

  static get GAME() {
    return { CAR_RACE: "CAR", CONTEST: "Contests" };
  }

  static get PLAY() {
    return this.PRODUCTS();
  }

  static get SCORE() {
    return this.PRODUCTS();
  }

  static get WINNERS() {
    return this.PRODUCTS();
  }

  static get SCORE() {
    return this.PRODUCTS();
  }

  static get WINNERS() {
    return this.PRODUCTS();
  }

  static get PAYMENT_ADD_TO_WALLET() {
    return "PAYMENT_ADD_TO_WALLET";
  }
  static get PAYMENT_JOIN_CONTEST() {
    return "PAYMENT_JOIN_CONTEST";
  }

  static get TYPE_GAMES() {
    return "TYPE_GAMES";
  }

  static get TYPE_CONTESTS() {
    return "TYPE_CONTESTS";
  }

  static get USER() {
    return "USER";
  }
  static get SET_TIMER() {
    return "SET_TIMER";
  }

  static get RESET_TIMER() {
    return "RESET_TIMER";
  }
  static get SET_INTERVAL_ID() {
    return "SET_INTERVAL_ID";
  }
  static get GET_INTERVAL_ID() {
    return "GET_INTERVAL_ID";
  }

  static get FREE_GAME_ACCESS_TIME() {
    return 30;
  }

  static get NO_OF_RECORDS() {
    return 9;
  }

  static get REMAINING_TIME_TO_JOIN() {
    return 5 * 60;
  }

  static get TRANSACTION_TYPE() {
    return "Wallet Transfer";
  }

  static get FROM_CONTEST() {
    return "FROM_CONTEST";
  }

  static get OPEN_WALLET() {
    return "OPEN_WALLET";
  }
  static get EXECUTE_CONTEST() {
    return "EXECUTE_CONTEST";
  }
  static get ACCOUNT_HOLDER_NAME() {
    return "ACCOUNT_HOLDER_NAME";
  }
  static get ACCOUNT_NO() {
    return "ACCOUNT_NO";
  }
  static get IFSC() {
    return "IFSC";
  }
  static get BANK_NAME() {
    return "BANK_NAME";
  }
  static get BANK_NAME() {
    return "BANK_NAME";
  }
  static get OTHER_BANK() {
    return "Other-Bank";
  }

  static get GAME_TYPE_CAR() {
    return "Car_Race";
  }

  static get GAME_TYPE_BIKE() {
    return "Bike_Race";
  }

  static get LOGOUT() {
    return "Logout";
  }
}

export default Constants;
