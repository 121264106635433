import {Link,Outlet} from "react-router-dom";
const InsideIndiaOfficeDetails = () => {
  
    // logMsg(useParams().mname)
    return (
        <div className="alert alert-primary" role="alert">
            <h2>My Office Details inside India</h2>
        </div>


    )
}

export default InsideIndiaOfficeDetails