import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../reducer/UseReducer";
import Constants from "../utils/Constants";
import { logMsg } from "../utils/Utils";

export const gameSlice = createSlice({
    name: 'game_slice',
    initialState: {
        sessionTime: 0
    },
    reducers: {

        decreaseSessionTime: (state) => {


            logMsg(`${state.sessionTime}`)
            if (state.sessionTime <= 0) {
                state.sessionTime = 0
            } else {
                --state.sessionTime
            }
        },

        setSessionTime: (state, newState) => {

            logMsg(`${state.sessionTime}`)

            state.sessionTime = newState.payload

        }

    }
})

export const { setSessionTime, decreaseSessionTime } = gameSlice.actions

export default gameSlice.reducer