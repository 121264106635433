
const InsideIndiaFlatDetails = () => {

    // logMsg(useParams().mname)
    return (

        <div >
            <h2>My Flat Details inside India</h2>

        </div>

    )
}

export default InsideIndiaFlatDetails