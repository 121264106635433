import React, { useState, useEffect } from "react";
import "./ViewPager.css";
import banner1 from "../res/exports_img_1.png";
import banner2 from "../res/exports_img_2.jpeg";
import banner3 from "../res/exports_img_3.png";
import banner4 from "../res/exports_img_4.png";
import banner5 from "../res/exports_img_5.png";
import banner6 from "../res/exports_img_6.png";
import banner7 from "../res/exports_img_7.png";
import banner8 from "../res/exports_img_8.png";
import banner9 from "../res/exports_img_9.png";
import banner10 from "../res/exports_img_10.png";

const contentArray = [
  { banner: banner1, text: <h1>Ultimate Gaming Experience</h1> },
  { banner: banner2, text: <h1>Ultimate Watching Moving & Series</h1> },
  { banner: banner3, text: <h1>Ultimate Utilities Payment</h1> },
  { banner: banner4, text: <h1>Ultimate Utilities Payment</h1> },
  { banner: banner5, text: <h1>Ultimate Utilities Payment</h1> },
  { banner: banner6, text: <h1>Ultimate Utilities Payment</h1> },
  { banner: banner7, text: <h1>Ultimate Utilities Payment</h1> },
  { banner: banner8, text: <h1>Ultimate Utilities Payment</h1> },
  { banner: banner9, text: <h1>Ultimate Utilities Payment</h1> },
  { banner: banner10, text: <h1>Ultimate Utilities Payment</h1> },
];

const ViewPager = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentArray.length);
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const unselected = (
    <div
      style={{
        width: "10px",
        height: "10px",
        backgroundColor: "white",
        border: "1px solid black",
        borderRadius: "5px",
        marginRight: "5px",
      }}
    ></div>
  );

  const selected = (
    <div
      style={{
        width: "10px",
        height: "10px",
        border: "1px solid black",
        backgroundColor: "red",
        borderRadius: "5px",
        marginRight: "5px",
      }}
    ></div>
  );
  return (
    <div className="container-viewpager">
      <div style={{ position: "relative" }} className="content-viewpager">
        <img
          className="content-item-viewpager"
          src={contentArray[currentIndex].banner}
        />
        <div className="text-parent-viewpager">
          {/* <h1>{contentArray[currentIndex].text}</h1> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          bottom: "20px",
        }}
      >
        {contentArray.map((item, index) =>
          currentIndex == index ? selected : unselected
        )}
      </div>
    </div>
  );
};

export default ViewPager;
