import './contactUs.css'
import { Link, NavLink, Outlet } from "react-router-dom";
import MyButton from '../widgets/MyButton';

const ContactUs = () => {
    return (
        <div className='parent-contact-us'>


            <div className='subparent-contact-us'>
                <h3 className='title-contact-us text-color-secondary'>Registered Address</h3>
                <p className='quote-contact-us text-color-ternary'>90B, Delhi - Jaipur Expy, Udyog Vihar, Sector 18, Gurugram, Haryana 122001

                </p>


                <h3 className='title-contact-us text-color-secondary'>Write To Us your Queries</h3>
                <p className='quote-contact-us text-color-ternary'>official.auropine@gmail.com</p>



                <h3 className='title-contact-us text-color-secondary'>Write To Us your Feedback</h3>
                <form className='quote-contact-us' onSubmit={(e) => {
                    e.preventDefault()

                }}>


                    <textarea style={{ width: "100%" }} defaultValue={"Your Valueable Feedback"}>

                    </textarea>
                    <MyButton btnValue={"Submit"} />


                </form>


                <h3 className='title-contact-us text-color-secondary'>Our Social Media Links</h3>
                <div className='other-links-contact-us'>

                    <Link className="social-media privacy-in-contact-us text-color-ternary" to="/fb_contact_us">Facebook</Link>
                    <pre>  </pre>
                    <Link className="social-media terms-in-contact-us text-color-ternary" to="/twitter_contact_us">Twitter</Link>

                </div>

            </div>



        </div>

    )
}

export default ContactUs