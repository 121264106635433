import React from 'react';
import './Cube.css'; // Import the updated CSS file

function Cube(props) {

    return (
        <div className="cube-container">
            <div className="cube">
                <div className="face front"></div>
                <div className="face back"></div>
                <div className="face left"></div>
                <div className="face right"></div>
                <div className="face top"></div>
                <div className="face bottom"></div>
            </div>
            <button className="start-button">{props.startText}</button>
        </div>
    );
}



export default Cube;
