import {useNavigate, useLocation, Outlet, Link} from 'react-router-dom'

const Twitter = () => {
    const navigate = useNavigate()

    return (

        <div className="alert alert-primary" role="alert">
            <h2> Welcome in Twitter</h2>

            <Link to="/twitter_details" state={"details_from_link"}

            > Twitter Details using Link
            </Link>

            <button
                onClick={()=> {
                    navigate("/twitter_details", {state: "details_from_navigate"})
                }}
            > Twitter Details using Navigate
            </button>

            <Outlet/>
        </div>

    )
}

export const TwitterDetails = () => {
    const location = useLocation()
    return (
        <div>
            <h2> Welcome in twitter detail page, you passed {location.state}</h2>
        </div>
    )
}

export default Twitter