import React from 'react';

import Cube from '../cube/Cube.js';

const UtilitiesIntro = () => {
  return (
    <div>

      <Cube startText={"Start Payments"}/>

    </div>
  );
};

export default UtilitiesIntro;
