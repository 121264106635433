import { createSlice } from "@reduxjs/toolkit";
import { logMsg } from "../utils/Utils";

export const userSlice = createSlice(
    {
        name: 'userReducer',
        initialState: {
            isLoggedIn: false,
            _id: '',
            userName: '',
            age: 0,
            email: '',
            gender: '',
            mobile: ''
        },
        reducers: {
            addUserAction: (state, newState) => {
                logMsg('user_slice_old', state)
                logMsg('user_slice_new', newState)
                state.isLoggedIn = newState.payload.isLoggedIn
                state._id = newState.payload._id
                state.userName = newState.payload.userName
                state.age = newState.payload.age
                state.email = newState.payload.email
                state.gender = newState.payload.gender
                state.mobile = newState.payload.mobile
            }, 
            addUserTest: (state) => {
                state.age=state.age+1
                logMsg('user_slice_old' )
                logMsg('user_slice_new')
              
            }
        }
    }
)

export const { addUserAction,addUserTest } = userSlice.actions

export default userSlice.reducer