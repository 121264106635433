import { menuItemsData } from "./menuItemsData.js";
import MenuItems from "./MenuItems";
import styles from "./Menu.module.css";
import logo from "../res/logo.png";
import profileSelected from "../res/profile_selected.png";
import profileUnselected from "../res/profile_unselected.png";
import { useEffect, useRef, useState } from "react";
import { event } from "jquery";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import List from "../comp/List.js";
import Constants from "../utils/Constants.js";
import hamburgerIcon from "../res/hamburger_icon.png";

const MyNav = () => {
  const userStateRedux = useSelector((state) => state.userReducer);
  const loggedInOptionRef = useRef();
  const navigate = useNavigate();
  const [isLoginBtnSelected, setLoginBtnSelected] = useState(false);
  const [isloggedInDetailsVisible, setloggedInDetailsVisible] = useState(false);
  const [isHamburgerExpended, setHamburgerExpended] = useState(true);

  function handleLogin(event) {
    event.preventDefault();
    console.log(`isLoggedIn=${userStateRedux.isLoggedIn}`);

    if (!userStateRedux.isLoggedIn) {
      navigate("/login");
    }
  }
  function onMouseEnterHandler(event) {
    console.log(`onMouseEnter`);
    setloggedInDetailsVisible(true);
  }
  function onMouseLeaveHandler(event) {
    console.log(`onMouseLeave`);
    setloggedInDetailsVisible(false);
  }
  function handleLoggedInDetailsClick(item) {
    console.log(`onClick====${item}`);
    switch (item) {
      case Constants.LOGOUT:
        navigate("/logout");
        break;
    }
  }
  useEffect(() => {
    console.log(`value = ${isloggedInDetailsVisible}`);
    const onMouseDownHandler = (event) => {
      if (
        isloggedInDetailsVisible &&
        loggedInOptionRef.current &&
        !loggedInOptionRef.current.contains(event.target)
      ) {
        setloggedInDetailsVisible(false);
      }
    };
    document.addEventListener("mousedown", onMouseDownHandler);
  }, [isloggedInDetailsVisible]);

  const loggedInOptions = ["Profile", Constants.LOGOUT];

  function handleHamburgerClick() {
    setHamburgerExpended((prev) => !prev);
  }

  return (
    <div
      className={isHamburgerExpended ? styles.headerExpended : styles.header}
    >
      <nav className={styles.headerContent}>
        <img className={styles.logo} src={logo} />
        <ul>
          {menuItemsData.map((item, index) => {
            return <MenuItems item={item} key={index} depth={1} />;
          })}
        </ul>

        <div
          className={styles.loginBtnParent}
          onMouseEnter={(event) => {
            event.preventDefault();
            setLoginBtnSelected(true);
            setloggedInDetailsVisible(true);
          }}
          onMouseLeave={(event) => {
            event.preventDefault();
            setLoginBtnSelected(false);
          }}
          onClick={handleLogin}
        >
          <p className={styles.loginText}>
            {userStateRedux.isLoggedIn ? userStateRedux.userName : "Login"}
          </p>
          <img
            className={styles.profileIcon}
            src={isLoginBtnSelected ? profileSelected : profileUnselected}
          />
        </div>

        <div
          className={styles.loggedInLayout}
          ref={loggedInOptionRef}
          onMouseEnter={(event) => {
            onMouseEnterHandler(event);
          }}
          onMouseLeave={(event) => {
            onMouseLeaveHandler(event);
          }}
          onClick={handleLogin}
        >
          {isloggedInDetailsVisible && userStateRedux.isLoggedIn ? (
            <List
              items={loggedInOptions}
              onClick={handleLoggedInDetailsClick}
            />
          ) : (
            <></>
          )}
        </div>
      </nav>
      <img
        className={styles.hamburgerIconStyle}
        src={hamburgerIcon}
        onClick={handleHamburgerClick}
      />
    </div>
  );
};

export default MyNav;
