const Error = () => {
    return (
        <div className="alert alert-primary" role="alert">

            <h2> 404 Not Found</h2>
        </div>

    )
}

export default Error