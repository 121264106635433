import React, { useState } from "react";
import { logMsg } from "../utils/Utils.js";
import "./MyRadioBtn.css"

function MyRadioBtn(props) {


    return (
   
            <input type={"radio"} className={props.customClass} 
                name={props.keyName} placeholder={props.defaultValue} value={props.value} checked={props.checked} onChange={props.onChange} />


    )
}

export default MyRadioBtn
