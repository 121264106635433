
import './CustomDialog.css'
import React, { useState, useRef, useEffect, useCallback, memo } from 'react'
import { useNavigate } from 'react-router-dom'

import Constants from './Constants.js'
import AxiosBase, { ErrorHandler } from '../axios/AxiosBase.js'
import { logMsg, logMsgExtra } from './Utils.js'

import MyButton from '../widgets/MyButton.js'




const CustomDialog = (props) => {


    const dialogParams = props.dialogParams

    let dialog = (
        <div className='dialog-box-custom'>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <p></p>
                <p style={{ fontSize: '25px', padding: '0', margin: '0', fontWeight: 'bold' }}>{"Alert"}</p>


                <img className='cross-custom' onClick={
                    props.onClickDialogCrossBtn
                } src={process.env.PUBLIC_URL + "cross.png"} />

            </div>


            <p style={{ padding: '30px', margin: '0', fontSize: '20px', textAlign: "center", wordBreak: 'normal' }}>{dialogParams.message}</p>


            <div style={{ width: '100%' }}>
                {

                    dialogParams.isShowCancelButton ?
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <MyButton onClick={props.onClickDialogCancelBtn} btnValue={"Cancel"} />
                            <p style={{ color: '#00000000' }}>{"....."}</p>
                            <MyButton onClick={props.onClickDialogOkayBtn} btnValue={"Ok"} />
                        </div> :
                        <MyButton onClick={
                            props.onClickDialogOkayBtn
                        } btnValue={"Ok"} />
                }


            </div>

        </div>
    );

    if (dialogParams.isOpen) {
        props.dialogStatusCallback(true)
    } else {
        dialog = null;
        props.dialogStatusCallback(false)
    }
    return (
        <div>

            <div>
                {

                    dialogParams.isOpen ?
                        <div className='outside-dialog-opened-style-custom'>
                            {dialog}
                        </div>
                        :
                        <div className='outside-dialog-closed-style-custom'>
                            {dialog}
                        </div>
                }
            </div>



        </div>
    )
}


export default memo(CustomDialog);
