
const OutsideIndiaFlatDetails = () => {

    // logMsg(useParams().mname)
    return (

        <div >
            <h2>My Flat Details outside India</h2>

        </div>

    )
}

export default OutsideIndiaFlatDetails