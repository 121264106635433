import React, { memo, useRef, useState } from 'react'
import editIcon from '../res/edit_white_icon.png'
import { logMsg } from './Utils'
function FileUpload(props) {


    const inputRef = useRef()

    function handleFile(event) {
        inputRef.current.click()
    }
    function handleFileChange(event) {
        
        const file = event.target.files && event.target.files[0]
        logMsg(`selected file is ${file.name}`)

        props.selectedFile(file)
    }
    return (
        <div style={props.style} >

            <img style={{ width: '20px', height: '20px'}} itemType='file' src={editIcon} onClick={handleFile}></img>

            <input style={{ display: 'none' }} type='file' ref={inputRef} onChange={handleFileChange}></input>
        </div>
    )
}

export default memo(FileUpload)