import { logMsg } from '../utils/Utils'
import './MyButton.css'
const MyButton = (props) => {

    const isHidden = props.hidden ? props.hidden : false

    const isDisabled = props.disabled ? props.disabled : false

    let customClasses = props.customClasses ? props.customClasses : [""]
    customClasses = customClasses.toString().replaceAll(',', ' ')


    let data_bs_toggle = ""
    let data_bs_target = ""
    let data_bs_dismiss = ""
    if (props.extras) {
        data_bs_toggle = props.extras.data_bs_toggle ? props.extras.data_bs_toggle : ""
        data_bs_target = props.extras.data_bs_target ? props.extras.data_bs_target : ""
        data_bs_dismiss = props.extras.data_bs_dismiss ? props.extras.data_bs_dismiss : ""
    }



    return (
        <div className='btn-parent'>
            <button hidden={isHidden} disabled={isDisabled} onClick={props.onClick} className={'btn-custom btn-component text-color-btn ' + customClasses}
                data-bs-toggle={data_bs_toggle} data-bs-target={data_bs_target} data-bs-dismiss={data_bs_dismiss}>{props.btnValue}</button>
        </div>

    )
}

export default MyButton