import MenuItems from "./MenuItems";
import styles from "../header/Menu.module.css";

const SubMenuDropdown = ({ submenu, depth, showDropdown }) => {
  return (
    <ul
      className={`${styles.subMenuDropDown} ${
        depth > 1 ? styles.subMenuDropDownStyle : ""
      } ${showDropdown ? styles.showDropdown : styles.hideDropdown}`}
    >
      {submenu.map((menu) => (
        <MenuItems item={menu} depth={depth + 1} />
      ))}
    </ul>
  );
};

export default SubMenuDropdown;
