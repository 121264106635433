import { menuItemsData } from "../header/menuItemsData";
import "./List.css";

const List = (props) => {
  const items = props.items;

  return (
    <ul
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        borderRadius: "10px",
        border: "1px solid gray",
        margin: "0",
      }}
    >
      {items.map((item, index) => (
        <li style={{ style: "none" }}>
          <p
            style={{ marginTop: "0px", paddingTop: "0px" }}
            onClick={(event) => {
              props.onClick(item);
            }}
          >
            {item}{" "}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default List;
