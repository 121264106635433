import './Login.css'
import { Await, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useCallback } from "react";
import MyEditBox from '../widgets/MyEditBox.js';
import Loader from "../loader/Loader.js";
import Constants from '../utils/Constants.js'


import MyButton from '../widgets/MyButton.js';
import MyCheckbox from '../widgets/MyCheckbox.js';
import { isValidEmail, isValidPasswordForLogin } from './Validation.js';
import { isValidPassword } from './Validation.js';
import AxiosBase, { ErrorHandler } from '../axios/AxiosBase.js';
import { getLanguage, logMsg, logMsgExtra } from '../utils/Utils.js';

// import { UserContext } from "../App.js";
import { useSelector, useDispatch } from "react-redux";
import { addUserAction } from '../../src/redux-toolkit/UserSlice.js';
import { setConfig, setCurrency } from '../../src/redux-toolkit/ConfigSlice.js';

import CustomDialog from '../utils/CustomDialog.js';
const Login = () => {
    // const { state, dispatch } = useContext(UserContext)

    const reduxDispatch = useDispatch()
    const { dfdfd } = useSelector(state => state.userReducer)

    const navigate = useNavigate()

    let [user, setUserData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [openDialogCommand, setOpenDialogCommand] = useState(Constants.DIALOG_DEFAULT_STATE)

    const addOpenDialogCommand = useCallback(({ msg = "", task = Constants.DIALOG_TASKS.NONE, isOpen = false, isShowCancelButton = false }) => {
        setOpenDialogCommand({ message: msg, task: task, isOpen: isOpen, isShowCancelButton: isShowCancelButton })
    }, [openDialogCommand])


    function getKeyAndValue(key, value) {
        user = { ...user, [key]: value }
        logMsgExtra("object is = ", user)

        setUserData(user)
    }

    async function callLogin(user) {
        user.pass = "Asdf1234!@"
        if (
            isValidEmail(user.email) &&
            isValidPasswordForLogin(user.pass)) {

            try {
                setLoading(true)

                const reqData = user

                const options = {
                    method: 'POST',
                    data: reqData,
                    url: "/login"

                };

                const res = await AxiosBase(options)
                const data = res.data

                const userData = { ...data.data, isLoggedIn: true }

                // dispatch({ type: Constants.USER, payload: userData })
                reduxDispatch(addUserAction(userData))


                const language = getLanguage()
                console.log(`language is ${language}`)

                navigate('/')


            } catch (error) {
                const msg = ErrorHandler(error)
                addOpenDialogCommand({ msg: msg, isOpen: true })

            } finally {
                setLoading(false)
            }
        }
    }

    async function decideCurrency() {
        await fetch('https://api.ipregistry.co/?key=tryout')
            .then(function (response) {
                return response.json();
            })
            .then(function (payload) {

                try {
                    if (payload.location.country.name === 'India') {
                        reduxDispatch(setCurrency(Constants.CURRENCY.INR))
                    } else {
                        reduxDispatch(setCurrency(Constants.CURRENCY.USD))
                    }
                } catch (e) {
                    reduxDispatch(setCurrency(Constants.CURRENCY.USD))
                }


            });
    }

    async function callConfig(userId) {

        try {
            setLoading(true)

            const reqData = {
                userId: userId
            }

            const options = {
                method: 'GET',
                params: {
                    userId: userId

                },
                url: "/config"

            };

            const res = await AxiosBase(options)
            const data = res.data


            // dispatch({ type: Constants.USER, payload: userData })
            reduxDispatch(setConfig(data))

            navigate('/home')

        } catch (error) {
            const msg = ErrorHandler(error)
            addOpenDialogCommand({ msg: msg, isOpen: true })

        } finally {
            setLoading(false)
        }


    }


    function handleSubmit(event) {
        event.preventDefault()
        logMsg(user)

        callLogin(user)
    }

    function handleForgotPassword(e) {
        e.preventDefault()
        navigate('/verify_email_forgot_pass')
    }

    return (
        <div className='root-login'>

            <CustomDialog
                dialogParams={openDialogCommand}

                onClickDialogCrossBtn={e => {
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogCancelBtn={e => {

                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogOkayBtn={async e => {
                    switch (openDialogCommand.task) {
                        case Constants.DIALOG_TASKS.GO_TO_LOGIN:
                            navigate('/login')
                            break;

                        default:
                            break;

                    }
                    addOpenDialogCommand({ isOpen: false })

                }}
                dialogStatusCallback={(isDialogOpened) => {
                    if (isDialogOpened) {

                    } else {

                    }
                }} >

            </CustomDialog>

            <form className='form-parent-login' onSubmit={handleSubmit}>
                <h2 className="intro-login text-color-ternary">Login</h2>

                <MyEditBox fieldValueObtained={getKeyAndValue} type={"email"} keyName={"email"} value={user.email} defaultValue={"Email"}
                    image={"account.webp"}></MyEditBox>
                <MyEditBox fieldValueObtained={getKeyAndValue} type={"password"} keyName={"pass"} value={user.pass} defaultValue={"Password"}
                    image={"account.webp"}></MyEditBox>


                <div className='root-remember-forgot-login'>
                    <div className='root-remember-login'>
                        <MyCheckbox />
                        <p className='text text-color-primary text-color-ternary'>Remember Me</p>

                    </div>

                    <p className='text forgot-password-login text-color-ternary' onClick={handleForgotPassword}>Forgot Password</p>
                </div>

                <MyButton btnValue={"Login"} />

                <p className='create-account-login text-color-ternary'
                    onClick={() => {
                        logMsg("clicking")
                        navigate('/register')
                    }}>New User? Create An Account</p>


            </form>
            {
                isLoading ? <Loader /> : <></>
            }




        </div>
    )

}

export default Login