import axios from 'axios'
import Constants from '../utils/Constants.js';
import { logMsg } from '../utils/Utils.js';

const controller = new AbortController()

var simple=function(){
    var textMultiple = {
         text1:"text1",
         text2:"text2"
     };
    return textMultiple;
 }

 window.simple=simple()
const AxiosBase = axios.create({
    baseURL: Constants.BASE_URL,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    signal: controller.signal
})

export const cancelReq = () => {
    if (controller) {
        logMsg(`cancelling`)
        controller.abort()
    }
}

// Add a request interceptor
AxiosBase.interceptors.request.use(function (config) {

    sendLogsToServer(`api request is = ${JSON.stringify(config, null, 4)}`)
    return config;
}, function (error) {
    // Do something with request error
    sendLogsToServer(`api request is wrong = ${JSON.stringify(error, null, 4)}`)
    return Promise.reject(error);
});

// Add a response interceptor
AxiosBase.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    sendLogsToServer(`api reponse is = ${JSON.stringify(response, null, 4)}`)
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    sendLogsToServer(`api reponse is wrong= ${JSON.stringify(error, null, 4)}`)
    return Promise.reject(error);
});


async function sendLogsToServer(logs) {
    logMsg(logs)
    // try {

    //     const reqData ={ logs: logs }

    //     const options = {
    //         method: 'POST',
    //         data: reqData,
    //         url: "/send_logs"
    //     }

    //     const res = await AxiosBase(options)

    //     const data = res.data
    //     logMsg(`logs sent succesfully at ${new Date().getTime()}`)

    // }
    // catch (error) {
    //     logMsg(`Failed sending logs at ${new Date().getTime()} : Erro is = ${error}`)

    // } finally {

    // }
}


export const ErrorHandler = (error) => {
    let errMsg = ""
    if (error.response) {
        // Request made and server responded

        logMsg(error.response.status);
        logMsg(error.response.data);
        if (error.response.data) {
            if (error.response.data.message) {
                errMsg = error.response.data.message
            } else {
                errMsg = "No message from error response"
            }
        }

        logMsg(error.response.headers);

    } else if (error.request) {
        // The request was made but no response was received
        errMsg = "No Response obtained from server"
    } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        errMsg = error.message
    } else {
        errMsg = "Unknown Error"
    }
    logMsg(errMsg)
    return errMsg
}


export default AxiosBase