import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../reducer/UseReducer";
import Constants from "../utils/Constants";
import { logMsg } from "../utils/Utils";

export const navigationSlice = createSlice({
    name: 'navigation_slice',
    initialState: {
        type: Constants.NAVIGATION.PLAY,
        productType: Constants.PRODUCTS.GAME,
        productSubtype: "",
        paymentType: Constants.PAYMENT_ADD_TO_WALLET
    },
    reducers: {

        setNavigationType: (state, newState) => {


            logMsg(`${state.type}`)
            state.type = newState.payload
        },

        setProductType: (state, newState) => {

            logMsg(`${state.sessionTime}`)

            state.productType = newState.payload

        },
        setProductSubtypeType: (state, newState) => {

            logMsg(`${state.sessionTime}`)

            state.productSubtype = newState.payload

        },

        setPaymentType: (state, newState) => {
            state.paymentType = newState.payload
        }

    }
})

export const { setNavigationType, setProductType, setProductSubtypeType, setPaymentType } = navigationSlice.actions

export default navigationSlice.reducer