
export const isValidName = (name) => {
    if (!name) {
        alert("Empty Name")
        return false
    }

    var nameRegex = /^[a-zA-Z_ ]+$/;
    if (!nameRegex.test(name)) {
        alert("Invalid Name")
        return false
    }
    return true
}

export const isValidUsername = (userName) => {
    if (!userName) {
        alert("Empty Username")
        return false
    }

    var userNameRegex = /^[a-zA-Z0-9]+$/;
    if (!userNameRegex.test(userName)) {
        alert("Invalid Username")
        return false
    }

    if (userName.length>8) {
        alert(`Username can have maximum 8 characters`)
        return false
    }
    return true
}

export const isValidMobile = (mobile) => {
    if (!mobile) {
        alert("Empty Mobile")
        return false
    }

    var mobileRegex = /^[0-9]+$/;
    if (!mobileRegex.test(mobile)) {
        alert("Invalid Mobile")
        return false
    }
    return true
}


export const isValidEmail = (email) => {
    if (!email) {
        alert("Empty Email")
        return false
    }

    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
        alert("Invalid Email")
        return false
    }
    return true
}

export const isValidGender = (gender) => {
    if (!gender) {
        alert("Empty Gender")
        return false
    }

    var genderRegex = /^[a-zA-Z]+$/;
    if (!genderRegex.test(gender)) {
        alert("Invalid Gender")
        return false
    }
    return true
}

export const isValidAge = (age) => {
    if (!age) {
        alert("Empty Age")
        return false
    }

    var ageRegex = /^[0-9]+$/;
    if (!ageRegex.test(age)) {
        alert("Invalid Username")
        return false
    }

    if (age < 18) {
        alert(`User below age of 18 not allowed`)
        return false
    }

    if (age > 99) {
        alert(`User above age of 99 not allowed`)
        return false
    }
    return true
}

export const isValidPassword = (password) => {
    if (!password) {
        alert("Empty Password")
        return false
    }

    if (password.length < 8) {
        alert("Password length must be minimum of 8 charaacters")
        return false
    }
    var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
        alert(`Invalid Password, requires below:\n->at least one uppercase letter,\n->at least one lowercase letter,\n->at least one number,\n->at least one special character from ${"@$!%*?&"} is needed`)
        return false
    }
    return true
}

export const isValidPasswordForLogin = (password) => {
    if (!password) {
        alert("Empty Password")
        return false
    }
    return true
}

export const isValidConfirmPassword = (password, confirmPassword) => {
    if (!password) {
        alert("Empty Password")
        return false
    }
    if (!confirmPassword) {
        alert("Empty Confirm Password")
        return false
    }

    if (password !== confirmPassword) {
        alert("Password & Confirm Password not matched")
        return false
    }
    return true
}

export const isValidAccountNo = (acntNum) => {
    if (!acntNum) {
        alert("Empty Account No")
        return false
    }

    let numberRegex = /^[0-9]+$/;
    if (!numberRegex.test(acntNum)) {
        alert("Invalid Account No")
        return false
    }
    return true
}

export const isValidIfsc = (ifsc) => {
    if (!ifsc) {
        alert("Empty IFSC code")
        return false
    }

    const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);

    if (!regex.test(ifsc)) {
        alert("Invalid IFSC code")
        return false
    }
    
    return true
}

export const isValidBankName = (name) => {
    if (!name) {
        alert("Bank is not selected")
        return false
    }
    return true
}
