import {Outlet} from "react-router-dom";

const Social = () => {
    return (
        <div className="alert alert-primary" role="alert">
            Social
            <Outlet/>
        </div>



    )
}

export default Social