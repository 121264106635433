import React, {
  useState,
  useEffect,
  createContext,
  useReducer,
  useCallback,
} from "react";

import "./App.css";
import { Routes, Route } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import Register from "./auth/Register.js";
import Profile from "./profile/Profile.js";
import About from "./about/about.js";
import Social from "./social/social.js";
import ContactUs from "./contactus/contactUs.js";
import Error from "./error/error.js";
import Logout from "./logout/Logout.js";
import ContactDetailsOffice from "./contactus/contact_details_office.js";
import ContactDetailsFlat from "./contactus/contact_details_flat.js";
import InsideIndiaOfficeDetails from "./contactus/InsideIndiaOfficeDetail.js";
import OutsideIndiaOfficeDetails from "./contactus/OutsideIndiaOfficeDetails.js";
import InsideIndiaFlatDetails from "./contactus/InsideIndiaFlatDetails.js";
import OutsideIndiaFlatDetails from "./contactus/OutsideIndiaFlatDetails.js";
import Twitter from "./social/Twitter.js";
import { TwitterDetails } from "./social/Twitter.js";
import Telegram from "./social/Telegram.js";
import Transactions from "./transactions/Transactions.js";
import TransactionDetails from "./transactions/TransactionDetails.js";
import Login from "./auth/Login.js";
import TermsAndConditions from "./terms/TermsAndConditions.js";
import PrivacyAndPolicy from "./privacy/PrivacyAndPolicy.js";
import EmailOTPVerification from "./profile/EmailOtpVerification.js";
import MobileVerification from "./profile/MobileVerification.js";
import Settings from "./settings/Settings.js";
import EmailVerification from "./profile/EmailVerificaton.js";
import EmailVerificationForgotPass from "./forgot_password/EmailVerificatonForgotPass";
import EmailOTPVerificationForgotPass from "./forgot_password/EmailOtpVerificationForgotPass";
import Home from "./new/Home.js";
import GameIntro from "./new/game/GameIntro.js";
import WatchIntro from "./new/watch/WatchIntro.js";
import UtilityIntro from "./new/utilities/UtilitiesIntro.js";
import TicTacToe from "./new/game/new/TicTacToe.js";
import SlidingPuzzle from "./new/game/new/SlidingPuzzle.js";

import MemoryGame from "./new/game/new/MemoryGame.js";
import GuessTheWord from "./new/game/new/GuessTheWord.js";

import BollywoodList from "./new/watch/movies/BollywoodList.js";

import HollywoodList from "./new/watch/movies/HollywoodList.js";
const App = () => {
  const userStateRedux = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const location = useLocation();

  const Routing = () => {
    return (
      <div className="app">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/game_intro" element={<GameIntro />} />
          <Route path="/watch_intro" element={<WatchIntro />} />
          <Route path="/utility_intro" element={<UtilityIntro />} />

          <Route path="/tic_tac_toe" element={<TicTacToe />} />
          <Route path="/sliding_number" element={<SlidingPuzzle />} />
          <Route path="/memory_game" element={<MemoryGame />} />
          <Route path="/guess_the_word_game" element={<GuessTheWord />} />

          <Route path="/bollywood_list" element={<BollywoodList />} />
          <Route path="/hollywood_list" element={<HollywoodList />} />
          <Route path="/transactions" element={<Transactions />}>
            <Route path=":txid" element={<TransactionDetails />} />{" "}
            {/* write Outlet in Parent else it will give error */}
          </Route>
          <Route path="/profile" element={<Profile />} />

          <Route path="/social" element={<Social />}>
            <Route path="twitter" element={<Twitter />} />
            <Route path="telegram" element={<Telegram />} />
          </Route>

          <Route path="/twitter_details" element={<TwitterDetails />} />

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />

          <Route path="/verify_mobile" element={<MobileVerification />} />
          <Route path="/verify_email" element={<EmailVerification />} />
          <Route path="/verify_email_otp" element={<EmailOTPVerification />} />

          <Route path="/about" element={<About />} />

          <Route path="/privacy_policy" element={<PrivacyAndPolicy />} />
          <Route path="/terms_conditions" element={<TermsAndConditions />} />
          <Route path="contact" element={<ContactUs />}>
            <Route path="office" element={<ContactDetailsOffice />}>
              <Route
                path="inside-india"
                element={<InsideIndiaOfficeDetails />}
              />
              <Route
                path="outside-india"
                element={<OutsideIndiaOfficeDetails />}
              />
            </Route>
            <Route path="flat" element={<ContactDetailsFlat />}>
              <Route path="inside-india" element={<InsideIndiaFlatDetails />} />
              <Route
                path="outside-india"
                element={<OutsideIndiaFlatDetails />}
              />
            </Route>
          </Route>
          <Route path="/settings" element={<Settings />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
    );
  };

  return (
    <Routing />

    // <div className="app">
    //     <Home />
    // </div>
  );
};

export default App;
