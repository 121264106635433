import './Loader.css'
import {Audio} from "react-loader-spinner";

const Loader =()=>{
    return (
        <div className="loader">
            <Audio
                height="50"
                width="50"
                color='green'
                ariaLabel='loading'
            />
        </div>
    )
}

export default Loader