

import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader.js'
import './AddBankDetailsDialog.css'

import MyButton from '../widgets/MyButton.js';


import axiosBase, { ErrorHandler } from '../axios/AxiosBase.js'
import MyEditBox from '../widgets/MyEditBox.js'
import Constants from '../utils/Constants.js'
import { isValidAccountNo, isValidBankName, isValidIfsc, isValidName } from '../auth/Validation.js'

import { logMsg, logMsgExtra } from '../utils/Utils.js'
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import CustomDialog from '../utils/CustomDialog.js';

const AddBankDetailsDialog = forwardRef((props, ref) => {
    const getUser = useSelector(state => state.userReducer)
    const DEFAULT_POSITION = -1
    const navigate = useNavigate()
    const btnRef = useRef()
    const [isLoading, setLoading] = useState(false)
    const [allListedBanks, setAllListedBanks] = useState([])
    const [listedBanksFiltered, setListedBanksFiltered] = useState([])

    const [accountHolderName, setAccountHolderName] = useState('')
    const [accountNo, setAccountNo] = useState(0)
    const [ifscCode, setIfscCode] = useState('')
    const [bankName, setBankName] = useState('')
    const [otherBankName, setOtherBankName] = useState('')

    const [openDialogCommand, setOpenDialogCommand] = useState(Constants.DIALOG_DEFAULT_STATE)
   
    const addOpenDialogCommand = useCallback(({ msg = "", task = Constants.DIALOG_TASKS.NONE, isOpen = false, isShowCancelButton = false }) => {
        setOpenDialogCommand({ message: msg, task: task, isOpen: isOpen, isShowCancelButton: isShowCancelButton })
    }, [openDialogCommand])
    let finalBankName = ''

    let isFirstTime = true;

    console.log('rendering page')
  
    const ifscCodeCallback = useCallback((value) => {
        logMsg('inside ifsc callback')
        setIfscCode(value)
    }, [ifscCode])


    const addDialogListener = () => {
        let myModalEl = document.getElementById('exampleModal')
        myModalEl.addEventListener('shown.bs.modal', function (event) {
            logMsg("dialog===is shown")
            props.dialogInterface(true)
        })
        myModalEl.addEventListener('hidden.bs.modal', function (event) {
            logMsg("dialog===is hidden")

            setAllListedBanks([])

            setAccountHolderName('')
            setAccountNo('')
            setIfscCode('')
            setBankName('')
            setOtherBankName('')

            finalBankName = ''


        })
    }
    useImperativeHandle(ref, () => ({
        openNow() {

            addDialogListener()
            getBankList()

        }
    }));




    const getBankList = async () => {


        try {
            setLoading(true)

            const options = {
                method: 'GET',
                url: "/all_banks"

            };

            const res = await axiosBase(options)
            const data = res.data


            const bankDataWithFlag = data.map(element => {
                logMsgExtra("inside_1", element)
                element.isSelected = false
                logMsgExtra("inside_2", element)
                return element
            });


            // logMsg(`data is ${data}`)
            // let bankData = [data.length]
            // data.forEach((item, pos) => {
            //     logMsg(`item is ${item}`)
            //     bankData[pos] = item.name
            // })
            logMsg(`bankNames are ${bankDataWithFlag}`)
            setAllListedBanks(bankDataWithFlag)
            setListedBanksFiltered(bankDataWithFlag)
        } catch (error) {
            const msg = ErrorHandler(error)

            addOpenDialogCommand({ msg: msg, isOpen: true })

        } finally {
            setLoading(false)
        }
    }


    function filterBankList(value) {
        const filtered = allListedBanks.filter((item, position) => {
            if (item.isSelected) {
                item.isSelected = false
            }

            if (value === '') {
                return true
            } else {
                if (item.name.toLowerCase().startsWith(value.toLowerCase())) {
                    return true
                } else {
                    if (item.name === Constants.OTHER_BANK) {
                        return true
                    } else {
                        return false
                    }
                }

            }

        })

        setListedBanksFiltered(filtered)
    }

    function getKeyAndValue(key, value) {


        logMsg(`getting in ${key} and ${value}`)


        switch (key) {
            case Constants.ACCOUNT_HOLDER_NAME:
                setAccountHolderName(value)
                break;
            case Constants.ACCOUNT_NO:
                setAccountNo(value)
                break;
            case Constants.IFSC:
                ifscCodeCallback(value)
                break;
            case Constants.BANK_NAME:
                setBankName(value)
                filterBankList(value)
                break;
            case Constants.OTHER_BANK:
                setOtherBankName(value)
                break;

        }

    }
    const handleDialogSubmit = () => {

        if (!isFirstTime) {
            return
        }

        if (isValidName(accountHolderName) &&
            isValidAccountNo(accountNo) &&
            isValidIfsc(ifscCode) &&
            setFinalBankNameAndCheckValidity()) {

            isFirstTime = false
            document.getElementsByClassName(`submit-dialog-add-banks`)[0].setAttribute("data-bs-dismiss", "modal");
            document.getElementsByClassName(`submit-dialog-add-banks`)[0].click()
            document.getElementsByClassName(`submit-dialog-add-banks`)[0].removeAttribute("data-bs-dismiss");
            // createContact()
            addBankDetails()
        } else {
            isFirstTime = true

        }

    }


    const setFinalBankNameAndCheckValidity = () => {
        for (let i = 0; i < allListedBanks.length; i++) {
            const item = allListedBanks[i]
            if (allListedBanks[i].isSelected) {
                if (item.name === Constants.OTHER_BANK) {
                    finalBankName = otherBankName
                } else {
                    finalBankName = item.name
                }

                break;
            } else {
                finalBankName = ''
                continue;

            }
        }
        return isValidBankName(finalBankName)
    }

    async function addBankDetails() {
        setLoading(true)

        try {
            const reqData = {

                "userData": {
                    userId: getUser._id,
                    userName: getUser.userName,
                    email: getUser.email,
                    contact: getUser.mobile,

                },
                "bankAccountHolderData": {
                    "name": accountHolderName,
                    "ifsc": ifscCode,
                    "accountNumber": accountNo,
                    "bankName": bankName
                }

            };

            const options = {
                method: 'POST',
                data: reqData,
                url: "/bank_details"

            };
            // const res = await axiosBase.post('/create_order',reqData,options)
            const res = await axiosBase(options)

            const data = res.data

            addOpenDialogCommand({ msg: data.message, task: Constants.DIALOG_TASKS.GO_TO_PROFILE, isOpen: true })
        } catch (error) {
            const msg = ErrorHandler(error)

            addOpenDialogCommand({ msg: msg, isOpen: true })

        } finally {
            setLoading(false)
        }
    }


    async function createContact() {

        setLoading(true)

        try {
            const reqData = {

                "userData": {
                    userId: getUser._id,
                    userName: getUser.userName,
                    email: getUser.email,
                    contact: getUser.mobile,

                },
                "bankAccountHolderData": {
                    "name": accountHolderName,
                    "ifsc": ifscCode,
                    "accountNumber": accountNo,
                    "bankName": bankName
                }

            };

            const options = {
                method: 'POST',
                data: reqData,
                url: "/create_contact"

            };
            // const res = await axiosBase.post('/create_order',reqData,options)
            const res = await axiosBase(options)

            const data = res.data
        } catch (error) {
            const msg = ErrorHandler(error)
            addOpenDialogCommand({ msg: msg, isOpen: true })


        } finally {
            setLoading(false)
        }
    }

    function changeHandler(e) {
        e.preventDefault()
    }

    let bankListUi = <ul className="list-group mt-3" >

        {


            listedBanksFiltered.map((item, position) => {

                try {
                    if (listedBanksFiltered[position].isSelected) {
                        { document.getElementsByClassName(`item-css-${position}`)[0].classList.add('active') }


                    } else {
                        { document.getElementsByClassName(`item-css-${position}`)[0].classList.remove('active') }
                    }

                } catch (e) {
                    logMsg(e)
                }

                return <li className={`list-group-item item-css-${position}`} aria-disabled="true" onClick={() => {


                    listedBanksFiltered.forEach((elem, index) => {

                        if (index === position) {
                            elem.isSelected = true

                        } else {
                            elem.isSelected = false
                        }
                    })


                    for (let i = 0; i < listedBanksFiltered.length; i++) {
                        if (listedBanksFiltered[i].isSelected) {
                            { document.getElementsByClassName(`item-css-${i}`)[0].classList.add('active') }

                            setBankName(listedBanksFiltered[i].name)

                        } else {
                            { document.getElementsByClassName(`item-css-${i}`)[0].classList.remove('active') }
                        }
                    }

                }}>
                    {item.name}

                </li>
            })

        }
    </ul >

    return (

        <div>
            <CustomDialog
                dialogParams={openDialogCommand}

                onClickDialogCrossBtn={e => {
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogCancelBtn={e => {

                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogOkayBtn={ e => {
                    switch (openDialogCommand.task) {
                        case Constants.DIALOG_TASKS.GO_TO_LOGIN:
                            navigate('/login')
                            break;
                        case Constants.DIALOG_TASKS.GO_TO_PROFILE:
                            navigate('/profile')
                            break;
                        default:
                            break;
                    }
                    addOpenDialogCommand({ isOpen: false })

                }}
                dialogStatusCallback={(isDialogOpened) => {
                    if (isDialogOpened) {

                    } else {

                    }
                }} >

            </CustomDialog>


            {

                < div >

                    < div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-lg">
                            <div className="modal-content model-content-available">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Select the bank</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id="dialog-content-parent">
                                    <form className='bank-form d-flex flex-column'>

                                        <MyEditBox fieldValueObtained={getKeyAndValue} type={"name"} keyName={Constants.ACCOUNT_HOLDER_NAME}
                                            value={accountHolderName} defaultValue={"Account Holder Name"} image={"account.webp"}>
                                        </MyEditBox>

                                        <MyEditBox fieldValueObtained={getKeyAndValue} type={"number"} keyName={Constants.ACCOUNT_NO}
                                            value={accountNo} defaultValue={"Account Number"} image={"account.webp"}>
                                        </MyEditBox>

                                        <MyEditBox fieldValueObtained={getKeyAndValue} type={"name"} keyName={Constants.IFSC}
                                            value={ifscCode} defaultValue={"IFSC"} image={"account.webp"}>
                                        </MyEditBox>

                                        <MyEditBox fieldValueObtained={getKeyAndValue} type={"name"} keyName={Constants.BANK_NAME}
                                            value={bankName} defaultValue={"Bank Name"} image={"account.webp"}>
                                        </MyEditBox>

                                        {
                                            (bankName === Constants.OTHER_BANK) ?
                                                <MyEditBox fieldValueObtained={getKeyAndValue} type={"name"} keyName={Constants.OTHER_BANK} value={otherBankName} defaultValue={"Other Bank Name"}
                                                    image={"account.webp"}></MyEditBox>
                                                :
                                                <></>
                                        }

                                    </form>

                                    {
                                        bankListUi

                                    }


                                </div>
                                <div className="modal-footer model-footer-available">

                                    <MyButton customClasses={["submit-dialog-add-banks"]} extras={{ "data_bs_dismiss": "modal" }} btnValue={"Ok"} onClick={(e) => {
                                        e.preventDefault()

                                        logMsg('ksjdkfjdkfjkdjflsjlkjs')

                                        handleDialogSubmit()
                                    }
                                    } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >



            }


        </div >



    )


})

export default AddBankDetailsDialog