import { useEffect, useRef, useState } from "react";
import SubMenuDropdown from "./SubMenuDropdown";
import styles from "../header/Menu.module.css";
import rightArrow from "../res/right_arrow.png";
import { useNavigate } from "react-router-dom";
import Constants from "../utils/Constants";
const MenuItems = ({ item, depth }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef();
  const navigate = useNavigate();

  const toggleDropdown = (event) => {
    event.preventDefault();
    setShowDropdown((prev) => !prev);
  };

  useEffect(() => {
    const onMouseDownHandler = (event) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", onMouseDownHandler);
  }, [showDropdown]);

  function onMouseEnterHandler(event) {
    setShowDropdown(true);
  }

  function onMouseLeaveHandler(event) {
    setShowDropdown(false);
  }

  function clickMenuItemHandler(event) {
    switch (item.title) {
      case Constants.MENU_ITEMS.ABOUT_US:
        navigate("/about");
        break;

      case Constants.MENU_ITEMS.SLIDING_NUMBER:
        navigate("/sliding_number");
        break;

      case Constants.MENU_ITEMS.TIC_TAC_TOE:
        navigate("/tic_tac_toe");
        break;

      case Constants.MENU_ITEMS.MEMORY_GAME:
        navigate("/memory_game");
        break;

      case Constants.MENU_ITEMS.GUESS_WORD:
        navigate("/guess_the_word_game");
        break;

      case Constants.MENU_ITEMS.BOLLYWOOD:
        navigate("/bollywood_list");
        break;
      case Constants.MENU_ITEMS.HOLLYWOOD:
        navigate("/hollywood_list");
        break;
    }
  }
  return item.submenu && item.submenu.length > 0 ? (
    <li className={styles.parent} ref={dropdownRef}>
      <div className={styles.btnStyle} onClick={toggleDropdown}>
        <p className={styles.text}>{item.title}</p>

        {depth > 1 ? <img className={styles.arrow} src={rightArrow} /> : <></>}
      </div>
      <SubMenuDropdown
        submenu={item.submenu}
        depth={depth}
        showDropdown={showDropdown}
      />
    </li>
  ) : (
    <li onClick={clickMenuItemHandler}>
      <p className={styles.text}>{item.title}</p>
    </li>
  );
};

export default MenuItems;
