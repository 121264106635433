import './MyCheckbox.css'
const MyCheckbox = (props) => {

    return (

        <input className="input-mycheck-box form-check-input" type="checkbox" value="" id="flexCheckDefault" />

    )
}

export default MyCheckbox