import React, { useState } from "react";
import "./GuessTheWord.css";

const words = ["react", "javascript", "hangman", "coding"];

const GuessTheWord = () => {
  const [word, setWord] = useState(words[Math.floor(Math.random() * words.length)]);
  const [guesses, setGuesses] = useState([]);
  const [attempts, setAttempts] = useState(0);  // Track the total number of attempts

  // Function to display the current state of the word with blanks
  const displayWord = () => {
    return word.split("").map((letter) => (guesses.includes(letter) ? letter : "_")).join(" ");
  };

  // Check if the game is won
  const isWinner = word.split("").every((letter) => guesses.includes(letter));

  // Handle a letter guess
  const handleGuess = (letter) => {
    if (guesses.includes(letter) || isWinner) return;  // Don't do anything if letter is already guessed or the game is won

    setGuesses([...guesses, letter]);  // Add the letter to the guesses array
    setAttempts(attempts + 1);  // Increment the number of attempts
  };

  // Restart the game
  const restartGame = () => {
    setWord(words[Math.floor(Math.random() * words.length)]);  // Select a new word
    setGuesses([]);  // Clear previous guesses
    setAttempts(0);  // Reset the attempts counter
  };

  // Alphabet buttons
  const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

  return (
    <div className="hangman-parent">
      <div className="hangman-container">
        <h1>Hangman</h1>
        <p>{`Attempts: ${attempts}`}</p>  {/* Display the number of attempts */}
        <p className="word-display">{displayWord()}</p>

        <div className="alphabet">
          {alphabet.map((letter) => (
            <button
              key={letter}
              onClick={() => handleGuess(letter)}
              disabled={guesses.includes(letter)}
            >
              {letter}
            </button>
          ))}
        </div>

        {isWinner && <p>🎉 Congratulations! You guessed the word: {word}</p>}
        {isWinner && <button onClick={restartGame}>Restart Game</button>}
      </div>
    </div>
  );
};


export default GuessTheWord;
