
import './CardDetailsDialog.css'
import React, { useState, useRef, useEffect, useCallback, memo } from 'react'
import { Link, useNavigate } from 'react-router-dom'


import Constants from '../utils/Constants.js'

import AxiosBase, { ErrorHandler } from '../axios/AxiosBase.js'
// import { logMsg, logMsgExtra } from '../Utils.js'

import { logMsg, logMsgExtra } from '../utils/Utils.js'

import { useSelector } from 'react-redux'
import MyButton from '../widgets/MyButton.js'
import CustomDialog from '../utils/CustomDialog.js'
import Loader from '../loader/Loader.js'
import MyEditBox from '../widgets/MyEditBox.js'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { createCustomer } from '../payments/stripe/StripeHandler.js'
import { CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'


const CardDetailsDialog = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const getUser = useSelector(state => state.userReducer)
    const navigate = useNavigate()
    let [cardDetails, setCardDetails] = useState({})
    const dialogParams = props.dialogParams

    const isOpenDialog = dialogParams.isOpen
    const availableExpMonths = [
        'MM', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
    ];
    const availableExpYears = [
        'YY', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35'
    ];
    // const [expMonth, setExpMonth] = useState(availableExpMonths[0])
    // const [expYear, setExpYear] = useState(availableExpYears[0])
    // const [cvv, setCVV] = useState("")
    // const joinModel = dialogParams.joinModel

    const [isLoading, setLoading] = useState(false)

    console.log("dkjfkdjfkdjfkjd")
    const [openDialogCommand, setOpenDialogCommand] = useState(Constants.DIALOG_DEFAULT_STATE)
    const addOpenDialogCommand = useCallback(({ msg = "", task = Constants.DIALOG_TASKS.NONE, isOpen = false, isShowCancelButton = false }) => {
        setOpenDialogCommand({ message: msg, task: task, isOpen: isOpen, isShowCancelButton: isShowCancelButton })
    }, [openDialogCommand])

    useEffect(() => {


    }, [])
    function getKeyAndValue(key, value) {
        cardDetails = { ...cardDetails, [key]: value }
        logMsgExtra("object is = ", cardDetails)

        setCardDetails(cardDetails)
    }

    // async function getAvailableNumbers() {


    //     try {
    //         setLoading(true)

    //         const options = {
    //             method: 'GET',
    //             url: `/available_contest_numbers/${joinModel.current.contestId}`

    //         };

    //         const res = await AxiosBase(options)
    //         const data = res.data
    //         const array = data.map(element => {
    //             logMsgExtra("inside_1", element)
    //             element.isSelected = false
    //             element.backgroundColor = "white"
    //             logMsgExtra("inside_2", element)
    //             return element
    //         });
    //         setAvailableNumner(array)


    //     } catch (error) {
    //         const msg = ErrorHandler(error)
    //         addOpenDialogCommand({ msg: msg, isOpen: true })

    //     } finally {
    //         setLoading(false)
    //     }


    // }

    const processCardDetails = async () => {
        cardDetails.userId = getUser._id
        const result = await createCustomer(cardDetails)
    }


    const submitPayment = async (e) => {
        e.preventDefault()
        if (!stripe || !elements) {
            return
        }

        const [error] = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/stripe_intent_redirection`
            }
        })

        if (error) {
            console.log(error)
        }

    }

    let dialog = (
        <div className='dialog-box-available-numbers'>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <p style={{ fontSize: '25px', padding: '0', margin: '0', fontWeight: 'bold' }}>{"Card Details"}</p>


                <img className='cross-available-numbers' onClick={
                    props.onClickDialogCrossBtn
                } src={process.env.PUBLIC_URL + "cross.png"} />

            </div>


            {/* <form style={{ width: '100%' }} >
                <MyEditBox fieldValueObtained={getKeyAndValue} type={"text"} keyName={"name"} value={cardDetails.name} defaultValue={"Card Holder Name"}
                    image={"account.webp"}></MyEditBox>

                <MyEditBox fieldValueObtained={getKeyAndValue} type={"email"} keyName={"email"} value={cardDetails.email} defaultValue={"Email Address"}
                    image={"account.webp"}></MyEditBox>


                <MyEditBox fieldValueObtained={getKeyAndValue} type={"text"} keyName={"cardNumber"} value={cardDetails.cardNumber} defaultValue={"Card Number"}
                    image={"account.webp"}></MyEditBox>

                <div style={{ width: '100%' }} className='exp-group-margin'>


                    <div className='exp-cvv-group'>
                        <p style={{ width: "50%", marginRight: "20px" }}>  Expiry</p>
                        <p style={{ width: "50%" }}> CVV</p>
                    </div>


                    <div className='exp-cvv-group'>
                        <div className='exp-group' style={{ width: "50%", marginRight: '10px' }}>
                            <div style={{ width: "50%" }}>
                                <Dropdown options={availableExpMonths} onChange={(e) => {
                                    getKeyAndValue("expMonth",e.value)
                                }} value={cardDetails.expMonth} placeholder="MM" />
                            </div>

                            <div style={{ width: "50%" }}>
                                <Dropdown options={availableExpYears} onChange={(e) => {
                        
                                    getKeyAndValue("expYear",e.value)
                                }} value={cardDetails.expYear} placeholder="YY" />
                            </div>

                        </div>

                        <div style={{ width: "50%", marginTop: "-10px" }}>
                            <MyEditBox fieldValueObtained={getKeyAndValue} type={"text"} keyName={"cvv"} value={cardDetails.cvv} defaultValue={"CVV"}
                                image={"account.webp"}></MyEditBox>
                        </div>


                    </div>

                </div>



            </form> */}

            <form style={{ width: '100%' }} onSubmit={submitPayment}>
                <PaymentElement />
            </form>


            <div style={{ width: '100%' }}>
                {

                    dialogParams.isShowCancelButton ?
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <MyButton onClick={props.onClickDialogCancelBtn} btnValue={"Cancel"} />
                            <p style={{ color: '#00000000' }}>{"....."}</p>
                            <MyButton onClick={submitPayment} btnValue={"Ok"} />
                        </div> :
                        <MyButton onClick={submitPayment} btnValue={"Ok"} />
                }


            </div>

        </div>
    );

    if (isOpenDialog) {
        props.dialogStatusCallback(true)
    } else {
        dialog = null;
        props.dialogStatusCallback(false)
    }
    return (
        <div>

            <div>
                {

                    isOpenDialog ?
                        <div className='outside-dialog-opened-style-available-numbers'>
                            {dialog}
                        </div>
                        :
                        <div className='outside-dialog-closed-style-available-numbers'>
                            {dialog}
                        </div>
                }
            </div>
            <CustomDialog
                dialogParams={openDialogCommand}

                onClickDialogCrossBtn={e => {
                    switch (openDialogCommand.task) {


                        case Constants.DIALOG_TASKS.GO_TO_PAYMENT_RAZORPAY:

                            break;

                        default:
                            break;

                    }
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogCancelBtn={e => {
                    switch (openDialogCommand.task) {

                        case Constants.DIALOG_TASKS.GO_TO_PAYMENT_RAZORPAY:

                            break;

                        default:
                            break;

                    }
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogOkayBtn={async e => {
                    switch (openDialogCommand.task) {

                        case Constants.DIALOG_TASKS.GO_TO_LOGIN:
                            navigate('/login')
                            break;
                        case Constants.DIALOG_TASKS.GO_TO_CONTEST_LIST:
                            navigate('/contest_list')
                            break;
                        case Constants.DIALOG_TASKS.DEDUCT_MONEY:


                            break;
                        case Constants.DIALOG_TASKS.GO_TO_PAYMENT_RAZORPAY:

                            break;

                        default:
                            break;

                    }
                    addOpenDialogCommand({ isOpen: false })

                }}
                dialogStatusCallback={(isDialogOpened) => {
                    if (isDialogOpened) {

                    } else {

                    }
                }} >

            </CustomDialog>

            {
                isLoading ? <Loader /> : <></>
            }

        </div>
    )
}


export default memo(CardDetailsDialog);
