import "./Register.css"
import MyEditBox from "../widgets/MyEditBox.js";

import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MyButton from "../widgets/MyButton.js";



import Loader from "../loader/Loader.js";
import { isValidName, isValidUsername, isValidMobile, isValidEmail, isValidAge, isValidGender, isValidPassword, isValidConfirmPassword } from "./Validation.js";
import AxiosBase, { ErrorHandler } from "../axios/AxiosBase.js";
import { logMsg, logMsgExtra } from "../utils/Utils.js";
import { Radio } from "react-loader-spinner";
import MyRadioBtn from "../widgets/MyRadioBtn";
import CustomDialog from "../utils/CustomDialog";
import Constants from "../utils/Constants";



function Register(props) {

    const navigate = useNavigate()

    const GENDER_MALE = 'male'
    const GENDER_FEMALE = 'female'

    let [user, setUser] = useState({})
    const [gender, setGender] = useState("")

    const [isLoading, setLoading] = useState(false)


    const [openDialogCommand, setOpenDialogCommand] = useState(Constants.DIALOG_DEFAULT_STATE)

    const addOpenDialogCommand = useCallback(({ msg = "", task = Constants.DIALOG_TASKS.NONE, isOpen = false, isShowCancelButton = false }) => {
        setOpenDialogCommand({ message: msg, task: task, isOpen: isOpen, isShowCancelButton: isShowCancelButton })
    }, [openDialogCommand])

    function getKeyAndValue(key, value) {
        logMsg(`key is ${key} and value is ${value}`)
        user = { ...user, [key]: value }

        logMsgExtra("user is ", user)
        setUser(user)
    }

    useEffect(() => {
        console.log(gender);
        user.gender = gender
    }, [gender])

    async function callRegistration(user) {
        logMsg(user)

        if (isValidName(user.name) &&
            isValidUsername(user.userName) &&
            isValidEmail(user.email) &&
            isValidMobile(user.mobile) &&
            isValidGender(gender) &&
            isValidAge(user.age) &&
            isValidPassword(user.pass) &&
            isValidConfirmPassword(user.pass, user.confirmPass)) {


            user.isMobileVerified = false
            user.isEmailVerified = false

            try {
                setLoading(true)
                const reqData = user
                const options = {
                    method: 'POST',
                    data: reqData,
                    url: "/register"

                };

                const res = await AxiosBase(options)
                const data = res.data

                navigate('/login')


            } catch (error) {
                const msg = ErrorHandler(error)

                addOpenDialogCommand({ msg: msg, isOpen: true })
            } finally {
                setLoading(false)
            }

        }

    }

    function handleGender(e) {
        setGender(e.target.value)


    }



    function handleSubmit(event) {
        event.preventDefault()
        logMsg(user)

        callRegistration(user)
    }

    return (
        <div className='root-register'>

            <CustomDialog
                dialogParams={openDialogCommand}

                onClickDialogCrossBtn={e => {
                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogCancelBtn={e => {

                    addOpenDialogCommand({ isOpen: false })
                }}
                onClickDialogOkayBtn={async e => {
                    switch (openDialogCommand.task) {
                        case Constants.DIALOG_TASKS.GO_TO_LOGIN:
                            navigate('/login')
                            break;

                        default:
                            break;

                    }
                    addOpenDialogCommand({ isOpen: false })

                }}
                dialogStatusCallback={(isDialogOpened) => {
                    if (isDialogOpened) {

                    } else {

                    }
                }} >

            </CustomDialog>

            <form className="form-parent-register" onSubmit={handleSubmit}>
                <h2 className='intro-text-register text-color-ternary'> Create Your Account</h2>
                <MyEditBox fieldValueObtained={getKeyAndValue} type={"text"} keyName={"name"} value={user.name} defaultValue={"Name"}
                    image={"account.webp"}></MyEditBox>

                <MyEditBox fieldValueObtained={getKeyAndValue} type={"text"} keyName={"userName"} value={user.userName} defaultValue={"User Name"}
                    image={"account.webp"}></MyEditBox>

                <MyEditBox fieldValueObtained={getKeyAndValue} type={"email"} keyName={"email"} value={user.email} defaultValue={"Email"}
                    image={"account.webp"}></MyEditBox>

                <MyEditBox fieldValueObtained={getKeyAndValue} type={"number"} keyName={"mobile"} value={user.mobile} defaultValue={"mobile"}
                    image={"account.webp"}></MyEditBox>


                <MyEditBox fieldValueObtained={getKeyAndValue} type={"number"} keyName={"age"} value={user.age} defaultValue={"Age"}
                    image={"account.webp"}></MyEditBox>

                <div className="parent-radio-register">

                    {/* <div className="parent-gender-register transgender-register">
                        <MyRadioBtn customClass={"gender-radio-register"} fieldValueObtained={getKeyAndValue} type={"radio"} keyName={"gender"} value={"Transgender"}
                            image={"account.webp"}></MyRadioBtn>
                        <p className="gender-value">Transgender</p>
                    </div> */}

                    <div className="parent-gender-register male-register">
                        <MyRadioBtn customClass={"gender-radio-register"} fieldValueObtained={getKeyAndValue} type={"radio"} keyName={"gender"} value={GENDER_MALE}
                            image={"account.webp"} checked={gender === GENDER_MALE} onChange={handleGender} > </MyRadioBtn>

                        <p className="gender-value">Male</p>

                    </div>

                    <div className="parent-gender-register female-register">
                        <MyRadioBtn customClass={"gender-radio-register"} fieldValueObtained={getKeyAndValue} type={"radio"} keyName={"gender"} value={GENDER_FEMALE}
                            image={"account.webp"} checked={gender === GENDER_FEMALE} onChange={handleGender} ></MyRadioBtn>
                        <p className="gender-value">Female</p>
                    </div>


                </div>

                <MyEditBox fieldValueObtained={getKeyAndValue} type={"password"} keyName={"pass"} value={user.pass} defaultValue={"Password"}
                    image={"account.webp"}></MyEditBox>

                <MyEditBox fieldValueObtained={getKeyAndValue} type={"password"} keyName={"confirmPass"} value={user.confirmPass} defaultValue={"Confirm Password"}
                    image={"account.webp"}></MyEditBox>
                <p className="separator-register"></p>
                <MyButton btnValue={"Register"} />

                <p className='already-registered-register text-color-ternary'
                    onClick={() => {
                        logMsg("clicking")
                        navigate('/login')
                    }}>Already User? Login</p>
            </form>
            {
                isLoading ? <Loader /> : ""
            }




        </div>
    )
}

export default Register
