import { configureStore } from "@reduxjs/toolkit";
import userReducer from './UserSlice.js'
import verificationReducer from './VerificationSlice.js'
import navigationReducers from './NavigationSlice.js'
import gameReducer from './GameSlice.js'
import configReducer from './ConfigSlice.js'
import { persistStore, persistReducer } from "redux-persist";
// import { storage } from "redux-persist/lib/types.js";
import storage from 'redux-persist/lib/storage'
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
    key: 'root',
    version: 1,
    storage
}

const conbinedReudcers= combineReducers({
    userReducer: userReducer,
    configReducer: configReducer,
    verificationReducer:verificationReducer,
    gameReducer:gameReducer,
    navigationReducers:navigationReducers
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        storage.removeItem('persist:root')
        return conbinedReudcers(undefined, action)
      }
    
      return conbinedReudcers(state, action)
  };

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware:(getDefaultMiddleware)=>getDefaultMiddleware({
        serializableCheck:false
    }),
    devTools:true
})

const Persistor = persistStore(store)

export { Persistor }
export default store