import './about.css'
import { useState, useEffect } from "react";
import axiosBase, { ErrorHandler } from '../axios/AxiosBase.js'
import Loader from "../loader/Loader.js";
import { logMsg } from "../utils/Utils.js";
import { Link } from 'react-router-dom';

const About = () => {

    const [value, setValue] = useState("")
    const [isLoading, setLoading] = useState(false)
    async function callAboutApi() {

        // logMsg("inside call api function")


        // try {
        //     setLoading(true)
        //     const options = {
        //         method: 'GET',
        //         url: "/about"

        //     };
        //     const res = await axiosBase(options)

        //     const data = res.data
        //     setValue(JSON.stringify(data))
        //     logMsg(data)
        // } catch (error) {
        //     ErrorHandler(error)
        // } finally {
        //     setLoading(false)
        // }


    }


    // useEffect(() => {
    //     logMsg("calling use effect")
    //     callAboutApi()
    // }, [])

  
    return (

        <div className='parent-about-us'>

            <div className='subparent-about-us'>

                <h2 className='title-about-us  text-color-secondary'>Our Vision</h2>
                <h4 className='subtitle-about-us  text-color-secondary'>Give Gamers Great Experience</h4>
                <p className='quote-about-us text-color-ternary'>No motivation is required to play the games, Remember your childhood playing video games
                    all day and night without being bored, forgetting everything but to complete the level of game.

                </p>

                <p className='poem-about-us  text-color-ternary'>
                    Those were the golden days,<br />
                    And are now back again,<br />
                    In the form of diamond shape,<br />
                    Play to get wealth gain
                </p>

                <h2 className='title-about-us text-color-secondary'>How we work</h2>
                <h4 className='subtitle-about-us  text-color-secondary'>Play & Earn, Participate & Earn</h4>
                <p className='poem-about-us text-color-ternary'>
                    Yes Its, true, Play the game and earn money,<br />
                    Buy game subscription, so that you can play and earn money,
                    After buying subscription, get free contest points,
                    Particiate in contest with these free points and again earn money.
                    Yeah, Its two way earning.
                </p>


                <h2 className='title-about-us text-color-secondary'>Who Works for Us</h2>
                <h4 className='subtitle-about-us  text-color-secondary'>Greatly Honest and Intelligent Individuals</h4>
                <p className='quote-about-us text-color-ternary'>Ganesh Pandey - Director of Auropine Software Private Limited</p>


                <p className='thoughts-about-us text-color-ternary'>
                    I don't work day and night,<br />
                    Makes sure my work is bright,<br />
                    Working hard is not my way,<br />
                    It's not harder, it's game play
                </p>




                <h2 className='title-about-us text-color-secondary'>Other Links</h2>
                <div className='other-links-about-us'>

                    <Link className="link-about-us privacy-in-about-us text-color-ternary" to="/privacy_policy">Privacy Policy</Link>
                    <pre>  </pre>
                    <Link className="link-about-us terms-in-about-us text-color-ternary" to="/terms_conditions">Terms & conditions</Link>
                    <pre>  </pre>
                    <Link className="link-about-us contacts-in-about-us text-color-ternary" to="/contact">Contact Us</Link>
                </div>

            </div>

        </div>

    )
}

export default About