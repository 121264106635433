import {Link, useParams} from "react-router-dom";

const ContactDetailsFlat = () => {

    // logMsg(useParams().mname)
    return (

        <div >
            <h2>I am from flat details</h2>

            <ul>
                <li>
                    <Link to="/contact/flat/inside-india">flat inside india</Link>
                </li>

                <li>
                    <Link to="/contact/flat/outside-india">flat outside india</Link>
                </li>
            </ul>

        </div>

    )
}

export default ContactDetailsFlat